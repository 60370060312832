import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CVEssayTOS from "./components/company_terms/CVEssayTOS";
import { ComplaintsProcedure } from "./components/company_terms/ComplaintsProcedure";
import { DeliveryPolicy } from "./components/company_terms/DeliveryPolicy";
import MITInfo from "./components/company_terms/MITInfo";
import PaymentPolicy from "./components/company_terms/PaymentPolicy";
import PrivacyPolicy from "./components/company_terms/PrivacyPolicyVN";
import { ProductInspectionPolicy } from "./components/company_terms/ProductInspectionPolicy";
import { RefundPolicy } from "./components/company_terms/RefundPolicy";
import TermsOfServices from "./components/company_terms/TermsOfServices";
import UsageInstruction from "./components/company_terms/UsageInstruction";
import MainPage from "./components/mains/MainPage";
import ScrollToTop from "./scrollToTop";
import "./styles/index.scss";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<MainPage />} key="main"></Route>
          <Route
            path="/privacy-policy"
            element={<PrivacyPolicy />}
            key="privacy-policy"
          />
          <Route
            path="/terms-of-services"
            element={<TermsOfServices />}
            key="terms-of-services"
          />
          <Route
            path="/services-policy"
            element={<CVEssayTOS />}
            key="services-policy"
          />
          <Route
            path="/payment-policy"
            element={<PaymentPolicy />}
            key="payment-policy"
          />
          <Route path="/mit-info" element={<MITInfo />} key="mit-info" />
          <Route
            path="/usage-instruction"
            element={<UsageInstruction />}
            key="usage-instruction"
          />
          <Route
            path="/product-inspection-policy"
            element={<ProductInspectionPolicy />}
            key="product-inspection-policy"
          />
          <Route
            path="/delivery-policy"
            element={<DeliveryPolicy />}
            key="delivery-policy"
          />
          <Route
            path="/complaints-procedure"
            element={<ComplaintsProcedure />}
            key="complaints-procedure"
          />
          <Route
            path="/refund-policy"
            element={<RefundPolicy />}
            key="refund-policy"
          />
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
