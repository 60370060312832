const Rocket = () => {
  return (
    <div className="rocket-wrapper">
      <div className="rocket-body">
        <div className="body"></div>
        <div className="fin fin-left"></div>
        <div className="fin fin-right"></div>
        <div className="window-border">
          <div className="window"></div>
          <div className="bolt bolt-top"></div>
          <div className="bolt bolt-bot"></div>
          <div className="bolt bolt-left"></div>
          <div className="bolt bolt-right"></div>
        </div>
        <div className="rocket-label">
          <span className="rocket-label-text">EZBRO</span>
        </div>
      </div>
      <div className="exhaust-flame"></div>
    </div>
  );
};

export default Rocket;
