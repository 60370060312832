import { Helmet } from "react-helmet";
import ui1 from "./UsageInstructionImages/ui1.png";
import ui2 from "./UsageInstructionImages/ui2.png";
import ui3 from "./UsageInstructionImages/ui3.png";
import ui4 from "./UsageInstructionImages/ui4.png";
import ui5 from "./UsageInstructionImages/ui5.png";
import ui6 from "./UsageInstructionImages/ui6.png";

const UsageInstruction = () => {
  return (
    <main className="privacy-content" id="UsageInstruction">
      <Helmet>
        <title>Hướng dẫn đặt hàng trực tuyến</title>
      </Helmet>
      <h1>Hướng dẫn đặt hàng trực tuyến</h1>
      <p>
        Để Tạo bài luận, Sửa luận tổng quát, Sửa luận IELTS, Task 1/2 Lập dàn ý
        chi tiết cho bài viết/bài nói IELTS, Tạo bài nói/bài luận IELTS mẫu,
        Phân tích bài đọc chuyên sâu, Chấm điểm bài nói trên website
        Cvhtechnology.com, người dùng có thể thực hiện theo quy trình sau:
      </p>
      <br />
      <p>
        <b>Bước 1:</b> Người dùng truy cập vào website Cvhtechnology.com.
      </p>
      <p>
        <b>Bước 2:</b> Người dùng nhấn chọn mục “Edu Tech” trên thanh chuyên mục
        và chọn “Chi tiết” để xem mô tả chi tiết dịch vụ.
      </p>
      {/* img */}
      <img src={ui1} width={600} alt="usage-instruction-1"/>
      <img src={ui2} width={600} alt="usage-instruction-2"/>
      <br/>
      <p>
        Sau đó, Người dùng nhấn chọn mục “Edu Tech” trên thanh chuyên mục và
        chọn “Trải nghiệm” để sử dụng dịch vụ
      </p>
      {/* img */}
      <img src={ui3} width={600} alt="usage-instruction-3"/>
      <br/>
      <p>
        Người dùng chọn “Đăng nhập với Google” để đăng nhập sử dụng dịch vụ.
      </p>
      {/* img */}
      <img src={ui4} width={600} alt="usage-instruction-4"/>
      <br />
      <p>
        <b>Bước 3:</b> Người dùng nhấn chọn mục “Nạp thêm EZBRO” để nạp tiền sử
        dụng dịch vụ.
      </p>
      {/* img */}
      <img src={ui5} width={200} alt="usage-instruction-5"/>
      <br />
      <p>
        <b>Bước 4:</b> Người dùng chọn Phương thức thanh toán, chọn số điểm
        EZBRO cần nạp và ấn “Tiếp tục”.
      </p>
      {/* img */}
      <img src={ui6} width={600} alt="usage-instruction-6"/>
      <br />
      <br />
      <b>Lưu ý:</b>
      <p>
        Trong vòng 03 giờ kể từ khi nhận được thông tin đặt hàng, nhân viên chăm
        sóc khách hàng của Công ty Cổ phần Công nghệ Sức khỏe Tiện lợi sẽ liên
        hệ trực tiếp với người mua hàng qua số điện thoại để xác nhận đơn hàng.
      </p>
      <p>
        Trường hợp người mua muốn hủy đơn hàng, người mua vui lòng liên hệ trực
        tiếp với chúng tôi qua hotline 0913963938 để được hỗ trợ.
      </p>
    </main>
  );
};

export default UsageInstruction;
