import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import StarBG from "../stylings/StartBG";
import About from "./About";
import Footer from "./Footer";
import Home from "./Home";
import Nav from "./Nav";
import Product from "./Product";
function MainPage() {
  ///create reference for scrollTrigger action
  const main = useRef();
  const loading = useRef(false);
  const [width, setWidth] = useState(window.innerWidth);

  ///listens for resizing
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  ///after done loading, set loading reference to true
  useEffect(() => {
    loading.current = true;
  }, []);

  //setting states for scrolling sections
  const [abt, setAbt] = useState(0);
  const [ess, setEss] = useState(0);
  const [med, setMed] = useState(0);
  const [currentSel, setCurrentSel] = useState("Home");

  ///initialize scrolling animation
  //only set animations if done mounting
  useLayoutEffect(() => {
    //activate the scrollTrigger plugin
    gsap.registerPlugin(ScrollTrigger);

    ///creates context
    const ctx = gsap.context((self) => {
      //animation for home section
      function Home() {
        //only triggers when user start scrolling on the home section
        function scrollHome() {
          const scrollAnimation = gsap.timeline({
            defaults: { duration: 1 },
            paused: true,
            scrollTrigger: {
              trigger: "#Home",
              start: "top top",
              end: "bottom top",
              scrub: 1,
              pin: true,
              anticipatePin: 1,
            },
          });

          scrollAnimation
            .addLabel("begin")
            .addLabel("middle")
            .to(
              [".home-titles-heading", ".button-wrapper"],
              { y: "-80vh", ease: "expo.inout" },
              "begin"
            )
            .to(
              [".illustration-description"],
              { y: "-80vh", ease: "expo.inout" },
              "begin"
            )
            .fromTo(
              ".clouds-wrapper",
              { y: 0 },
              { y: "80vh" },
              "begin"
            );

          return scrollAnimation;
        }

        //animation starts on Home section when page loads
        const startAnimation = gsap.timeline();

        startAnimation
          .addLabel("start")
          //fade in
          .fromTo(
            ".home-titles-heading",
            { opacity: 0 },
            {
              opacity: 1,
              y: 0,
              ease: "back.out",
            },
            "start+=0.2"
          )
          .fromTo(
            ".button-wrapper",
            { opacity: 0 },
            {
              opacity: 1,
              y: 0,
              ease: "back.out",
            },
            "start+=0.5"
          )
          .from(
            ".illustration-description",
            { opacity: 0, ease: "expo.out" },
            "start+=1.3"
          )
          .from(
            ".rocket-wrapper",
            { opacity: 0, y: "100%", ease: "expo.out", duration: 1 },
            "start+=1"
          )
          .from(
            ".clouds-wrapper",
            { opacity: 0, y: "-50%", duration: 5 },
            "start+=2"
          )
          .add(scrollHome());

        return startAnimation;
      }

      //animation for the about section
      function About() {
        const about = gsap.timeline({
          defaults: {
            duration: 1,
          },
          scrollTrigger: {
            trigger: "#About",
            start: "top top",
            end: "+=200%",
            scrub: 1,
            pin: true,
            anticipatePin: 1,
          },
        });
        about
          .addLabel("begin")
          .from(
            ".intro-one-about",
            { yPercent: 50, opacity: 0, ease: "slow" },
            "begin"
          )
          .fromTo(
            ".intro-one-about",
            { yPercent: 0, opacity: 1 },
            { yPercent: -50, opacity: 0, ease: "slow" },
            "begin+=2"
          )
          .from(".ai-icon-wrapper", { opacity: 0, ease: "slow" })
          .from(".ai-icon-wrapper", {
            rotateX: 0,
            y: 150,
            ease: "slow",
            delay: 1,
          })

          .addLabel("flash")
          .from(
            ".title-parent",
            { opacity: 0, ease: "sine", y: "-5%" },
            "flash+=1"
          )
          .from(
            ".title-child",
            { opacity: 0, ease: "sine", y: "-5%" },
            "flash+=1.5"
          )
          .fromTo(
            ".about-button",
            { opacity: 0 },
            { opacity: 1, ease: "sine" },
            "flash+=1.7"
          );
        return about;
      }

      /// animation for essay and medical sections
      function Essay() {
        const essay = gsap.timeline({
          defaults: {
            duration: 0.5,
          },
          scrollTrigger: {
            trigger: "#Essay",
            start: "top top",
            end: "+=200%",
            pin: true,
            anticipatePin: 1,
            scrub: 1,
          },
        });

        essay
          .addLabel("begin")
          .from(".product-title-essay", { opacity: 0 }, "begin")
          .from(".product-des-essay", { opacity: 0 }, "begin+=0.2")
          .fromTo(
            ".explore-button-essay",
            { opacity: 0 },
            { opacity: 1 },
            "begin+=0.6"
          )
          .fromTo(
            ".ezbro-table-button",
            { opacity: 0 },
            { opacity: 1 },
            "begin+=0.8"
          );
      }

      function Med() {
        const med = gsap.timeline({
          defaults: {
            duration: 0.5,
          },
          scrollTrigger: {
            trigger: "#Med",
            start: "top top",
            end: "+=200%",
            pin: true,
            anticipatePin: 1,
            scrub: 1,
          },
        });

        med
          .addLabel("begin")
          .from(".product-title-med", { opacity: 0 }, "begin")
          .from(".product-des-med", { opacity: 0 }, "begin+=0.2")
          .from(".product-feat-med", { opacity: 0 }, "begin+=0.4");
      }
      //BIG animation timeline
      const launchAnimation = gsap.timeline();
      launchAnimation.add(Home()).add(About()).add(Essay()).add(Med());
    }, main);
    ///revert context when component dismounts
    return () => ctx.revert();
  }, []);

  ///after page doneloading, get the height
  ///of page components
  useEffect(() => {
    /// helper function to set many section heights
    function setSecHeight(height, section) {
      if (section === "About") {
        setAbt(height);
      } else if (section === "Essay") {
        setEss(height);
      } else if (section === "Med") {
        setMed(height);
      }
    }

    //after done loading, mount the sections
    if (loading.current) {
      const sections = ["About", "Essay", "Med"];
      sections.forEach((section, index) => {
        if (section !== "Med") {
          //set any other than Medical section, since the essay section has
          //a special height (4 + the service information height)
          setSecHeight(window.innerHeight * (4 + index * 3) + 5, section);
        } else {
          const essServiceHeight = document.querySelector(
            ".service-information"
          ).offsetHeight;
          setSecHeight(
            window.innerHeight * (4 + index * 3) + essServiceHeight + 5,
            section
          );
        }
      });

      //add a listener for scrolling to each section => change nav bar styles accordingly
      window.addEventListener("scroll", () => {
        if (
          document.getElementById("Footer").getBoundingClientRect().top <
          window.innerHeight
        ) {
          setCurrentSel("Foo");
        } else if (
          document.getElementById("Med").getBoundingClientRect().top <= 0
        ) {
          setCurrentSel("Med");
        } else if (
          document.getElementById("Essay").getBoundingClientRect().top <= 0
        ) {
          setCurrentSel("Essay");
        } else if (
          document.getElementById("About").getBoundingClientRect().top <= 0
        ) {
          setCurrentSel("About");
        } else {
          setCurrentSel("Home");
        }
      });
    }
  }, [loading, width]);

  /// props for product display
  const essayProps = {
    id: "Essay",
    name: "Education Tech",
    des: "Viết - sửa luận bằng AI",
    det: [
      "Đồng hành cùng bạn",
      "Nhu cầu đa dạng",
      "Định dạng linh hoạt",
      "Tiết kiệm tài chính",
      "Đáp ứng nhu cầu",
    ],
    note: [
      "Hỗ trợ soạn thảo bài luận đầu tiên - xuất phát từ ý tưởng và phong cách viết của bạn",
      "Phù hợp trong việc viết luận du học, viết luận trong trường học ở mọi cấp độ ",
      "Hỗ trợ cả Tiếng Việt và Anh",
      "Giúp bạn tiết kiệm thời gian và tiền bạc, giảm thiểu việc đầu tư nhiều chi phí cho các trung tâm không đáng tin cậy",
      "Cơ chế sửa luận để giúp bài luận được rõ ý hơn theo yêu cầu và tránh các lỗi sai cơ bản",
    ],
    ill: ["🖋️", "🎓", "🌏", "💵", "✨"],
    cls: "essay",
  };

  const medProps = {
    id: "Med",
    name: "Medical Tech",
    des: "Tư vấn y tế bằng AI (Coming Soon)",
    det: ["Trợ thủ đắc lực", "Thao tác đơn giản", "", "", ""],
    note: [
      "Giúp tư vấn bạn vào khoa khám dựa trên triệu chứng, và hỗ trợ tự động đăng ký lịch khám",
      "Nhắn tin yêu cầu và để AI giúp đỡ với tất cả các thủ tục cần thiết",
      "",
      "",
      "",
    ],
    ill: ["💪", "📱", "", "", ""],
    cls: "med",
  };

  return (
    <div ref={main}>
      <Nav
        section={currentSel}
        setSection={() => setCurrentSel}
        abt={abt}
        essay={ess}
        med={med}
        width={width}
      />
      <StarBG />
      <Home scrollTo={abt} />
      <About scrollTo={ess} />
      <Product props={essayProps} scrollTo={ess} />
      <Product props={medProps} />
      {/* <PreFooter /> */}
      <Footer width={width} />
    </div>
  );
}

export default MainPage;
