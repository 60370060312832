import { Helmet } from "react-helmet";

export const DeliveryPolicy = () => {
  return (
    <main className="privacy-content" id="DeliveryPolicy">
      <Helmet>Chính sách vận chuyển, giao nhận</Helmet>
      <h1>Chính sách vận chuyển, giao nhận</h1>
      <p>
        Sau khi Người dùng đặt mua điểm EZBRO thành công, trong vòng 03 giờ,
        nhân viên chăm sóc khách hàng của Công ty Cổ phần Công nghệ Sức khỏe
        Tiện lợi sẽ liên hệ trực tiếp với học viên qua số điện thoại để xác nhận
        đơn hàng.
      </p>
      <p>
        Sau khi chúng tôi xác nhận Người dùng đã hoàn thành việc thanh toán đơn
        hàng, trong vòng 24 giờ, Người dùng sẽ nhận được cập nhật điểm EZBRO vào
        tài khoản.
      </p>
      <br />
      <b>
        <i>Lưu ý:</i>
      </b>
      <p>
        Trong quá trình mua hàng, nếu có bất kỳ vấn đề gì, học viên vui lòng
        liên hệ ngay với cvhtechnology.com qua:
      </p>
      <ul>
        <li>Hotline: 0913 963 938</li>
        <li>Email: cvhtechnology@gmail.com</li>
      </ul>
    </main>
  );
};
