import React from "react";
import { Helmet } from "react-helmet";

function CVEssayTOS() {
  return (
    <div className="privacy-content" id="CVEssayTOS">
      <Helmet>
        <title>Chính sách dịch vụ</title>
      </Helmet>
      <h1>Chính sách dịch vụ</h1>
      <h2>1. Đối với EzBro: </h2>
      <p>
        Hình thức cung cấp dịch vụ tương tác với người dùng thông qua website
        ứng dụng{" "}
        <a href="https://ezbro.io/" target="_blank" rel="noreferrer">
          EzBro AI
        </a>
      </p>
      <p>
        Người dùng mới sẽ được free 5 bros cho mọi tính năng hỗ trợ IELTS và xử
        lý bài luận, sau đó sẽ được 1 free bros mỗi ngày.
      </p>

      <p>
        Người dùng sẽ cần phải đăng nhập vào tài khoản ở{" "}
        <a href="https://ezbro.io/" target="_blank" rel="noreferrer">
          đây
        </a>{" "}
        (có thể truy cập qua phần dịch vụ trên website chính).
      </p>
      <p>Thanh toán khi mua thêm bros là không hoàn trả.</p>
      <p>
        Đối với tất cả dịch vụ và tính năng, người dùng có thể mua thêm số lượt
        dùng (bros) bằng hình thức thanh toán thẻ nhanh chóng thông qua cổng
        thanh toán ngay trong website ứng dụng. Lượt dùng sẽ được đảm bảo về
        chất lượng và thời gian phản hồi. Trong trường hợp có lỗi, vui lòng liên
        hệ với chúng tôi để có được dịch vụ chăm sóc khách hàng nhanh chóng.
      </p>
      <p>
        CVH Technology có trách nhiệm cung cấp dịch vụ như đã cam kết trong
        chính sách và phản hồi các yêu cầu của người dùng. Người dùng có trách
        nhiệm được miêu tả trong điều khoản sử dụng.
      </p>
      <p>
        Người dùng có trách nhiệm kiểm tra tài khoản sau khi mua bros và thông
        báo cho CVH Technology qua sđt hoặc email nếu có vấn đề số lượng bros
        chưa được áp dụng vào tài khoản.
      </p>
    </div>
  );
}

export default CVEssayTOS;
