import React from 'react';

function GlassPanel({ ill, det, note }) {

    return (
        <>
            {det !== "" ?
                <div className="glass-panel">
                    <p className="background">{ill}</p>
                    <h3 className="glass-title">{det}</h3>
                    <p className="glass-note">{note}</p>
                </div> : null}
        </>

    )
}

export default GlassPanel;