import React from "react";
import { Helmet } from "react-helmet";

function TermsOfServices() {
  return (
    <div className="privacy-content" id="Terms-Of-Services-VN">
      <Helmet>
        <title>Điều khoản sử dụng</title>
      </Helmet>
      <h1>Điều khoản sử dụng CVHTech</h1>
      <div className="table-of-contents">
        <a href="#general-intr">Giới thiệu chung</a>
        <a href="#privacy-func">Chức năng bảo mật</a>
        <a href="#usage-limit">Giới hạn về quyền sử dụng</a>
        <a href="#app-policies">Quy định phần mềm</a>
        <a href="#account-privacy">Tài khoản và chính sách bảo mật</a>
        <a href="#payment-terms">
          Quy định về khoản phí và thanh toán của dịch vụ
        </a>
        <a href="#responsibility-ex">Loại trừ trách nhiệm</a>
        <a href="#exclusions">
          Các trường hợp loại trừ và giới hạn trách nhiệm pháp lý
        </a>
        <a href="#connecting-parties">Liên kết các trang thuộc bên thứ ba</a>
        <a href="#using-terms">Điều khoản sử dụng dịch vụ của chúng tôi</a>
        <a href="#violation-terms">Vi phạm các điều khoản dịch vụ</a>
        <a href="#ensure">Đảm bảo và cam đoan của người dùng </a>
        <a href="#validity">Tính có hiệu lực theo từng phần</a>
        <a href="#law-control">Luật điều chỉnh</a>
        <a href="#general-terms">Các quy định chung</a>
      </div>

      <h2 id="general-intr">1. Giới thiệu chung</h2>
      <p>
        Chào mừng bạn đến với phần mềm ứng dụng CVHTech. Vui lòng đọc kỹ các
        Điều Khoản Dịch Vụ sau đây trước khi tiếp tục truy cập hoặc sử dụng các
        dịch vụ của Phần mềm, để bạn biết được các quyền lợi và nghĩa vụ hợp
        pháp của mình liên quan đến Công ty cổ phần Công Nghệ Sức Khỏe Tiện Lợi
        và các bên thứ ba có liên kết (gọi chung là “chúng tôi” hoặc “của chúng
        tôi”).
      </p>
      <p>
        “Các Dịch Vụ” chúng tôi cung cấp hoặc phát hành bao gồm:
        <ul>
          <li> Phần mềm CVEnglish, CVMed</li>
          <li> Các tính năng hoặc tiện ích được cung cấp trên Phần mềm</li>
          <li>
            {" "}
            Mọi thông tin, các Phần mềm được liên kết, các tính năng, dữ liệu,
            văn bản, hình ảnh, hình chụp, đồ họa, nhạc, âm thanh, video, thông
            báo, tag, nội dung, lập trình, phần mềm, các dịch vụ ứng dụng (bao
            gồm nhưng không giới hạn ở bất kỳ dịch vụ ứng dụng di động nào) hoặc
            các tài liệu khác được cung cấp thông qua Phần mềm hoặc các dịch vụ
            liên quan của nó (gọi chung là “Nội Dung”). Bất kỳ tính năng mới nào
            được thêm vào hoặc để tăng cường Các Dịch Vụ đều phải tuân thủ các
            Điều Khoản Dịch Vụ này.
          </li>
        </ul>
      </p>
      <p>
        BẰNG VIỆC SỬ DỤNG CÁC DỊCH VỤ HOẶC TIẾP TỤC TRUY CẬP TRANG WEB, BẠN CHO
        BIẾT RẰNG BẠN CHẤP NHẬN, KHÔNG RÚT LẠI, CÁC ĐIỀU KHOẢN DỊCH VỤ NÀY. NẾU
        BẠN KHÔNG ĐỒNG Ý VỚI CÁC ĐIỀU KHOẢN NÀY, VUI LÒNG KHÔNG SỬ DỤNG CÁC DỊCH
        VỤ CỦA CHÚNG TÔI HAY TIẾP TỤC TRUY CẬP PHẦN MỀM.
      </p>

      <p>
        Chúng tôi có quyền chỉnh sửa các Điều Khoản Dịch Vụ này vào bất kỳ lúc
        nào mà không cần thông báo cho người dùng. Việc bạn tiếp tục sử dụng Các
        Dịch Vụ, Phần mềm, hoặc Tài Khoản Của Bạn sẽ được xem là sự chấp nhận,
        không rút lại đối với các điều khoản chỉnh sửa đó.
      </p>
      <p>
        Chúng tôi có quyền thay đổi, điều chỉnh, đình chỉ hoặc ngưng bất kỳ phần
        nào của Phần mềm này hoặc Các Dịch Vụ vào bất kỳ lúc nào. Chúng tôi có
        thể ra mắt Các Dịch Vụ nhất định hoặc các tính năng của chúng trong một
        phiên bản beta, phiên bản này có thể không hoạt động chính xác hoặc theo
        cùng cách như phiên bản cuối cùng, và chúng tôi sẽ không chịu trách
        nhiệm pháp lý trong các trường hợp đó. Chúng tôi cũng có thể toàn quyền
        áp dụng giới hạn đối với các tính năng nhất định hoặc hạn chế quyền truy
        cập của bạn đối với một phần hoặc toàn bộ Phần mềm hoặc Các Dịch Vụ mà
        không cần thông báo hoặc phải chịu trách nhiệm pháp lý.
      </p>
      <p>
        Chúng tôi có quyền từ chối cho phép bạn truy cập Phần mềm hoặc Các Dịch
        Vụ vì bất kỳ lý do gì.
      </p>
      <h2 id="privacy-func">2. Chức năng bảo mật</h2>
      <p>
        Chúng tôi rất coi trọng việc bảo mật thông tin của bạn. Để bảo vệ các
        quyền của bạn một cách hiệu quả hơn, chúng tôi đã cung cấp Chính Sách
        Bảo Mật trên Phần mềm để giải thích chi tiết về các cách thức bảo mật
        thông tin của chúng tôi. Vui lòng xem Chính Sách Bảo Mật để hiểu được
        cách thức chúng tôi thu thập và sử dụng thông tin liên kết với Tài Khoản
        của bạn và/hoặc việc bạn sử dụng Các Dịch Vụ. Bằng việc sử dụng Các Dịch
        Vụ hoặc đồng ý với các Điều Khoản Dịch Vụ này, bạn đồng ý cho phép chúng
        tôi thu thập, sử dụng, tiết lộ và/hoặc xử lý Nội Dung và dữ liệu cá nhân
        của bạn như mô tả trong Chính Sách Bảo Mật của chúng tôi.
      </p>
      <p>
        Người dùng nào lưu giữ dữ liệu cá nhân của một người dùng khác (“Bên
        Nhận Thông Tin”) phải:
        <ul>
          <li>Tuân thủ mọi điều luật bảo vệ dữ liệu cá nhân hiện hành;</li>
          <li>
            Phải được Bên Tiết Lộ Thông Tin cho phép truy cập và lưu giữ bằng
            văn bản hoặc lời nói hoặc bất kỳ mội phương thức nào; và
          </li>
          <li>
            Chỉ sử dụng thông tin của Bên Tiết Lộ Thông Tin để đăng ký sử dụng
            Các Dịch Vụ giúp cho Bên Tiết Lộ Thông Tin trên Phần mềm.
          </li>
        </ul>
      </p>

      <h2 id="usage-limit">3. Giới hạn về quyền sử dụng </h2>
      <p>
        Chúng tôi cấp cho bạn quyền sử dụng có giới hạn để truy cập và sử dụng
        Các Dịch Vụ tuân theo các điều khoản và điều kiện của Điều Khoản Dịch Vụ
        này vì mục đích sử dụng cá nhân. Việc cấp quyền này không cho phép bạn
        sử dụng vì bất kỳ mục đích thương mại nào hay sử dụng vì mục đích phái
        sinh đối với Các Dịch Vụ này (bao gồm nhưng không giới hạn ở bất kỳ yếu
        tố riêng lẻ nào hay Nội Dung của nó). Tất cả Nội Dung, thương hiệu, nhãn
        hiệu dịch vụ, tên thương hiệu, logo và tài sản trí tuệ khác được hiển
        thị trên Phần mềm là tài sản của Chúng tôi và của các chủ sở hữu bên thứ
        ba được chỉ rõ trên Phần mềm, nếu có. Bất kỳ đối tượng nào truy cập vào
        Phần mềm đều không có quyền hoặc được cấp phép một cách trực tiếp hoặc
        gián tiếp sử dụng hoặc sao chép bất kỳ Nội dung, thương hiệu, nhãn hiệu
        dịch vụ, tên thương hiệu, logo hay bất kỳ tài sản trí tuệ nào khác và
        cũng không có đối tượng nào truy cập vào Phần mềm được yêu cầu bất kỳ
        quyền, quyền sở hữu hay quyền lợi nào liên quan đến các đối tượng trên.
        Bằng việc sử dụng hoặc truy cập Các Dịch Vụ, bạn đồng ý tuân thủ các qui
        định pháp luật về sở hữu trí tuệ hiện hành đối với vấn đề bảo hộ bản
        quyền, thương hiệu, nhãn hiệu dịch vụ, Các Dịch Vụ, Phần mềm và Nội Dung
        của nó. Bạn đồng ý không sao chép, phát tán, tái xuất bản, gửi, trưng
        bày công khai, trình diễn công khai, điều chỉnh, sửa đổi, cho thuê, bán,
        hay tạo phó bản của bất kỳ phần nào bất kể quy mô của Các Dịch Vụ, Phần
        mềm hoặc Nội Dung của nó. Nếu không có sự đồng ý trước bằng văn bản của
        chúng tôi, Bạn cũng không được nhân bản hoặc chỉnh sửa một phần hay toàn
        bộ nội dung của Phần mềm này trên máy chủ khác hoặc như một phần bất kể
        quy mô của bất kỳ Phần mềm nào khác. Ngoài ra, bạn đồng ý rằng bạn sẽ
        không sử dụng bất kỳ công cụ robot, spider hay bất kỳ thiết bị tự động
        hay quy trình thủ công nào khác để theo dõi hay sao chép Nội Dung của
        chúng tôi, nếu không có sự đồng ý trước bằng văn bản của chúng tôi (thỏa
        thuận này áp dụng cho các công cụ tìm kiếm cơ bản trên các webside kết
        nối người dùng trực tiếp đến website đó).
      </p>
      <p>
        Chúng tôi hoan nghênh bạn liên kết đến Phần mềm này từ Phần mềm của bạn,
        miễn là Phần mềm của bạn không ngụ ý rằng Chúng tôi ủng hộ hay liên kết
        với Phần mềm của bạn. Bạn thừa nhận rằng Chúng tôi, vào bất kỳ lúc nào,
        có thể tự quyền quyết định về việc ngưng cung cấp bất kỳ phần nào của
        Các Dịch Vụ mà không cần thông báo.
      </p>

      <h2 id="app-policies">4. Quy định phần mềm</h2>
      <p>
        Trừ phi kèm theo một thỏa thuận cấp phép riêng, bất kỳ phần mềm nào được
        chúng tôi cung cấp cho bạn như một phần của Các Dịch Vụ sẽ tuân thủ các
        điều khoản của Điều Khoản Dịch Vụ này. Phần mềm được cấp phép sử dụng,
        không bán, và Chúng tôi bảo lưu tất cả các quyền đối với phần mềm mà
        Chúng tôi không cấp. Bất kỳ lệnh hay mã nào của bên thứ ba, được liên
        kết đến hoặc được tham chiếu từ Các Dịch Vụ, đều được cấp phép cho bạn
        bởi các bên thứ ba sở hữu các mã script hoặc mã đó, không phải bởi Chúng
        tôi.
      </p>

      <h2 id="account-privacy">5. Tài khoản và chính sách bảo mật</h2>
      <p>
        Một số chức năng trong Các Dịch Vụ của chúng tôi yêu cầu phải đăng nhập
        bằng cách chọn số điện thoại định danh người dùng duy nhất (“ID Người
        Dùng”) và nhập code xác nhận, và bằng cách cung cấp một số thông tin cá
        nhân nhất định. Bạn có thể sử dụng Tài Khoản của mình để tiếp cận/truy
        cập các chức năng, Phần mềm hoặc Dịch vụ mà chúng tôi đã cho phép truy
        cập hoặc chúng tôi đã có liên kết hay hợp tác. Chúng tôi đã không xem
        xét, hay nhận trách nhiệm đối với bất kỳ nội dung, chức năng, bảo mật,
        dịch vụ, chính sách bảo mật nào của bên thứ ba, hay các hoạt động khác
        của các sản phẩm, Phần mềm hoặc dịch vụ đó. Trong trường hợp đó, điều
        khoản dịch vụ dành cho những sản phẩm, website, dịch vụ đó, gồm cả chính
        sách bảo mật tương ứng, vẫn được áp dụng khi bạn sử dụng sản phẩm,
        website, dịch vụ đó ngay cả khi những điều khoản bảo mật khác với Điều
        khoản dịch vụ/Chính sách bảo mật của chúng tôi.
      </p>
      <p>
        Bạn đồng ý
        <ul>
          <li>
            Bảo mật thông tin Tài Khoản của bạn và chỉ sử dụng ID Người Dùng của
            bạn khi đăng nhập,
          </li>
          <li>
            Đảm bảo rằng bạn đăng xuất ra khỏi tài khoản của mình vào cuối mỗi
            phiên truy cập trên Phần mềm,
          </li>
          <li>
            Thông báo ngay cho Chúng tôi bất kỳ trường hợp nào sử dụng trái phép
            ID Người Dùng và/hoặc thông tin của bạn, và
          </li>
          <li>
            Đảm bảo rằng thông tin Tài Khoản của bạn là chính xác và cập nhật.
            Bạn hoàn toàn chịu trách nhiệm đối với mọi hoạt động diễn ra dưới
            tên ID Người Dùng và Tài Khoản của bạn ngay cả khi các hoạt động hay
            việc sử dụng đó không phải do bạn thực hiện. Chúng tôi sẽ không chịu
            trách nhiệm pháp lý đối với bất kỳ tổn thất hay thiệt hại nào phát
            sinh từ việc tài khoản của bạn bị sử dụng trái phép hoặc việc bạn
            không tuân thủ điều khoản này.
          </li>
        </ul>
      </p>
      <p>
        Bạn đồng ý rằng Chúng tôi có thể, vì bất kỳ lý do gì, tự quyền quyết
        định và không cần thông báo hay chịu trách nhiệm pháp lý đối với bạn hay
        bất kỳ bên thứ ba nào, ngay lập tức chấm dứt Tài Khoản và ID Người Dùng
        của bạn, và xóa hay gỡ bỏ bất kỳ Nội Dung nào liên kết với Tài Khoản và
        ID Người Dùng của bạn ra khỏi Trang Web. Căn cứ chấm dứt tài khoản có
        thể bao gồm, nhưng không giới hạn:
        <ul>
          <li>
            Vi phạm nội dung hoặc tinh thần của các Điều Khoản Dịch Vụ này,
          </li>
          <li>
            Có hành vi gian lận, quấy rối, lăng mạ, đe dọa hoặc xúc phạm hoặc
          </li>
          <li>
            Có hành vi gây hại cho người dùng khác, các bên thứ ba, hoặc lợi ích
            của Chúng tôi. Hành vi sử dụng Tài Khoản cho mục đích phi pháp, gian
            lận, quấy rối, lăng mạ, đe dọa hay xúc phạm có thể bị báo cho các cơ
            quan thực thi pháp luật mà không cần thông báo cho bạn.
          </li>
        </ul>
      </p>
      <h2 id="payment-terms">
        6. Quy định về khoản phí và thanh toán của dịch vụ
      </h2>
      <p>
        Nếu có một khoản phí liên quan đến giao dịch đăng ký sử dụng toàn
        bộ/hoặc một phần Các Dịch Vụ, bạn đồng ý thanh toán khoản phí đó để nhận
        quyền sử dụng và/hoặc nhận quyền lợi của Các Dịch Vụ đó. Giá dành cho
        việc đăng ký sử dụng Các Dịch Vụ không bao gồm các khoản thuế hiện hành
        và phí đổi tiền, trừ trường hợp qui định khác. Bạn hoàn toàn chịu trách
        nhiệm đóng các khoản thuế hay phí khác đó. Chúng tôi có thể đình chỉ
        hoặc hủy việc đăng ký sử dụng của bạn vào Các Dịch Vụ nếu chúng tôi
        không nhận được thanh toán đầy đủ từ bạn trong thời hạn thanh toán quy
        định. Việc đình chỉ hoặc hủy Các Dịch Vụ vì không thanh toán có thể dẫn
        đến mất quyền truy cập và sử dụng Các Dich Vụ và Nội Dung của nó.
      </p>
      <p>
        Để thanh toán phí áp dụng cho Các Dịch Vụ, bạn sẽ được yêu cầu chọn một
        phương thức thanh toán phù hợp trước khi bạn hoàn thành giao dịch đăng
        ký sử dụng các dịch vụ hoặc tại thời điểm bạn đăng ký Dịch Vụ đó. Bạn có
        thể truy cập và thay đổi thông tin tài khoản thanh toán và phương thức
        thanh toán của mình vào bất kỳ lúc nào. Bạn đồng ý cho phép Chúng tôi
        thu thêm các khoản phí tương ứng với phương thức thanh toán của bạn,
        được cung cấp bởi ngân hàng phát hành của bạn hoặc mạng lưới thanh toán
        áp dụng.
      </p>
      <p>
        Bằng việc cung cấp một phương thức thanh toán cho Chúng tôi, bạn:
        <ul>
          <li>
            Đảm bảo rằng bạn được phép sử dụng phương thức thanh toán mà bạn đã
            cung cấp và rằng bất kỳ thông tin thanh toán nào bạn cung cấp đều là
            đúng sự thực và chính xác;
          </li>
          <li>
            Cho phép Chúng tôi tính phí cho bạn đối với Các Dịch Vụ, dùng phương
            thức thanh toán mà bạn lựa chọn; và
          </li>
          <li>
            Cho phép Chúng tôi tính phí cho bạn đối với bất kỳ tính năng có trả
            phí nào của Các Dịch Vụ mà bạn lựa chọn đăng ký hoặc sử dụng trong
            thời gian các Điều Khoản Dịch Vụ này có hiệu lực.
          </li>
        </ul>
      </p>
      <p>
        Tùy vào bản chất của giao dịch, chúng tôi có thể gửi hóa đơn cho bạn
        <ul>
          <li>Tại thời điểm đăng ký sử dụng dịch vụ;</li>
          <li>Hoặc sau khi đăng ký sử dụng dịch vụ hoàn tất.</li>
        </ul>
      </p>
      <p>
        Chúng tôi có thể thay đổi giá của bất kỳ Dịch Vụ và/hoặc loại phí nào
        vào bất kỳ lúc nào, và chúng tôi không có trách nhiệm thông báo đến bạn
        bằng bất kỳ phương thức nào, ngoài việc đăng tải trên Phân mềm. Nếu giá
        của các Dịch Vụ, hoặc phí đang hiển thị trên trang web, điều đó có nghĩa
        mức giá đó đang có giá trị áp dụng đối với Dịch Vụ hoặc loại phí đó.
      </p>
      <p>
        Một khi đã đăng ký hoàn tất, chúng tôi sẽ gửi cho bạn những loại phiếu
        xác nhận dịch vụ (“phiếu xác nhận”) qua sms, email và trong trong Phần
        mềm. Dịch vụ của bạn sẽ có giá trị sử dụng được nêu rõ trong các phiếu
        xác nhận mà chúng tôi gửi đến bạn. Khi Tài Khoản của bạn bị chấm dứt vì
        bất kỳ lý do gì hoặc nếu chúng tôi không còn có thể phục vụ Tài Khoản
        của bạn một cách hợp pháp (ví dụ, nếu bạn không đồng ý cho chúng tôi sử
        dụng dữ liệu cá nhân của bạn nữa), Chúng tôi có quyền xóa hoặc vô hiệu
        Tài Khoản của bạn và tất cả các Phiếu xác nhận trong Tài khoản của bạn
        sẽ bị mất và không còn giá trị sử dụng.
      </p>
      <p>
        Trừ phi có quy định khác theo luật hiện hành hoặc các điều khoản thành
        văn của một Dịch Vụ, mọi giao dịch đăng ký hoàn tất là cuối cùng và
        không hoàn lại. Bạn có thể hủy bất kỳ Dịch Vụ nào vào bất kỳ thời điểm
        nào, nhưng mọi khoản thanh toán trước có thể sẽ không hoàn lại, hoặc chỉ
        hoàn lại 1 phần tùy vào thời điểm bạn xác nhận hủy, quy định này sẽ được
        nêu rõ trong phần Quy Định Sử Dụng Phần Mềm.
      </p>

      <h2 id="responsibility-ex">7. Loại trừ trách nhiệm</h2>
      <p>
        CHÚNG TÔI KHÔNG ĐẢM BẢO RẰNG CÁC DỊCH VỤ, PHẦN MỀM NÀY HOẶC CÁC CHỨC
        NĂNG TRONG PHẦN MỀM SẼ LUÔN KHẢ DỤNG, CÓ THỂ TRUY CẬP, KHÔNG BỊ GIÁN
        ĐOẠN, KỊP THỜI, AN TOÀN, CHÍNH XÁC, HOÀN CHỈNH HAY KHÔNG CÓ LỖI, RẰNG
        CÁC KHIẾM KHUYẾT, NẾU CÓ, SẼ ĐƯỢC KHẮC PHỤC, HAY RẰNG PHẦN MỀM NÀY
        VÀ/HOẶC MÁY CHỦ CUNG CẤP PHẦN MỀM NÀY LÀ KHÔNG CÓ VIRUS, ĐỒNG HỒ, HẸN
        GIỜ, BỘ ĐẾM, S U, KHÓA PHẦN MỀM, THIẾT BỊ DROP DEAD, TROIHJAN, ĐỊNH
        TUYẾN, TRAP DOOR, BOM HẸN GIỜ HOẶC BẤT KỲ MÃ, CHỈ THỊ, CHƯƠNG TRÌNH HAY
        THÀNH PHẦN CÓ HẠI NÀO KHÁC.
      </p>
      <p>
        BẠN XÁC NHẬN RẰNG BẠN HOÀN TOÀN CHỊU RỦI RO PHÁT SINH TỪ VIỆC SỬ DỤNG
        HOẶC VẬN HÀNH PHẦN MỀM VÀ/HOẶC CÁC DỊCH VỤ THEO MỨC ĐỘ TỐI ĐA ĐƯỢC PHÁP
        LUẬT CHO PHÉP.
      </p>
      <p>
        Một số khu vực không cho phép loại trừ trách nhiệm đối với những bảo đảm
        bất thành văn, do đó một số hoặc tất cả tuyên bố loại trừ bên trên có
        thể không áp dụng cho bạn.
      </p>

      <h2 id="exclusions">
        8. Các trường hợp loại trừ và giới hạn trách nhiệm pháp lý
      </h2>
      <p>
        TRONG MỌI TRƯỜNG HỢP, CHÚNG TÔI SẼ KHÔNG CHỊU TRÁCH NHIỆM PHÁP LÝ, DÙ LÀ
        DƯỚI HÌNH THỨC HỢP ĐỒNG, BẢO ĐẢM, LỖI DO SƠ SUẤT (DÙ LÀ CHỦ ĐỘNG, BỊ
        ĐỘNG HAY QUY GÁN), TRÁCH NHIỆM ĐỐI VỚI SẢN PHẨM HAY TRÁCH NHIỆM PHÁP LÝ
        HAY NGUYÊN NHÂN KHÁC), HOẶC NGUYÊN NHÂN TỐ TỤNG THEO LUẬT ĐỊNH, LUẬT LỆ,
        ĐỐI VỚI VIỆC MẤT QUYỀN SỬ DỤNG, MẤT LỢI NHUẬN, DOANH THU, UY TÍN HOẶC
        TIẾT KIỆM DỰ KIẾN HOẶC ĐỐI VỚI BẤT KỲ THIỆT HẠI GIÁN TIẾP, VÔ TÌNH, ĐẶC
        BIỆT HAY HẬU QUẢ NÀO (BAO GỒM NHƯNG KHÔNG GIỚI HẠN Ở MẤT DỮ LIỆU, GIÁN
        ĐOẠN DỊCH VỤ, LỖI MÁY TÍNH, ĐIỆN THOẠI DI ĐỘNG HAY THIẾT BỊ DI ĐỘNG)
        PHÁT SINH TỪ HOẶC LIÊN QUAN ĐẾN VIỆC SỬ DỤNG HAY KHÔNG THỂ SỬ DỤNG PHẦN
        MỀM NÀY HOẶC CÁC DỊCH VỤ, BAO GỒM, NHƯNG KHÔNG GIỚI HẠN, BẤT KỲ THIỆT
        HẠI NÀO PHÁT SINH TỪ ĐÓ, NGAY CẢ KHI CHÚNG TÔI ĐÃ ĐƯỢC THÔNG BÁO VỀ KHẢ
        NĂNG XẢY RA NHỮNG THIỆT HẠI ĐÓ.
      </p>
      <p>
        QUYỀN DUY NHẤT CỦA BẠN LIÊN QUAN ĐẾN CÁC SỰ CỐ HOẶC SỰ KHÔNG HÀI LÒNG
        NÀO VỚI CÁC DỊCH VỤ LÀ CHẤM DỨT TÀI KHOẢN CỦA BẠN VÀ NGƯNG SỬ DỤNG CÁC
        DỊCH VỤ.
      </p>
      <p>
        BẤT KỂ CÁC PHẦN TRƯỚC, NẾU CHÚNG TÔI BỊ TÒA ÁN CÓ THẨM QUYỀN TUYÊN LÀ
        PHẢI CHỊU TRÁCH NHIỆM PHÁP LÝ (ĐỐI VỚI LỖI BẤT CẨN HIỂN NHIÊN), TRÁCH
        NHIỆM PHÁP LÝ CỦA CHÚNG TÔI ĐỐI VỚI BẠN HOẶC ĐỐI VỚI BẤT KỲ BÊN THỨ BA
        NÀO KHÁC CHỈ GIỚI HẠN Ở MỨC DƯỚI 500.000VNĐ (NĂM TRĂM NGHÌN ĐỒNG CHẴN).
      </p>

      <h2 id="connecting-parties">9. Liên kết các trang thuộc bên thứ ba</h2>
      <p>
        Các đường dẫn được cung cấp trên Phần mềm này sẽ đưa người dùng rời khỏi
        Phần mềm này. Các đường dẫn này chỉ mang tính tham khảo, và các trang mà
        chúng liên kết đến không thuộc quyền kiểm soát của chúng tôi dưới bất kỳ
        hình thức nào và do đó bạn tự chịu rủi ro khi truy cập các trang đó. Do
        đó, Chúng tôi không chịu trách nhiệm dưới bất kỳ hình thức nào đối với
        nội dung của bất kỳ Phần mềm bên thứ ba nào hoặc bất kỳ liên kết nào có
        trong một trang được liên kết bao gồm bất kỳ thay đổi hay cập nhật nào
        đối với các trang đó. Chúng tôi cung cấp các liên kết này ở đây chỉ nhằm
        mục đích thuận tiện cho bạn, và việc bao gồm bất kỳ liên kết nào không
        ngụ ý hay bày tỏ dưới bất kỳ hình thức nào về sự liên kết, ủng hộ hay
        tài trợ của Chúng tôi đối với bất kỳ Phần mềm nào được liên kết và/hoặc
        bất kỳ nội dung nào của nó trong đó.
      </p>

      <h2 id="using-terms">10. Điều khoản sử dụng dịch vụ của chúng tôi</h2>
      <p>
        Quyền sử dụng Phần mềm này và Các Dịch Vụ có hiệu lực đến khi bị chấm
        dứt. Quyền sử dụng này sẽ chấm dứt như quy định trong các Điều Khoản
        Dịch Vụ này hoặc nếu bạn không tuân thủ bất kỳ điều khoản hay điều kiện
        nào của các Điều Khoản Dịch Vụ này. Trong trường hợp đó, Chúng tôi sẽ
        không cần thông báo để áp dụng sự chấm dứt đó.
      </p>

      <p>
        Bạn đồng ý không:
        <ul>
          <li>
            tải lên, đăng tin, gửi email, gửi hoặc cung cấp theo cách khác bất
            kỳ Nội Dung nào được cho là bất hợp pháp, có hại, đe dọa, xúc phạm,
            quấy rối, làm lo lắng, tra tấn, nhục mạ, phản cảm, tục tĩu, phỉ
            báng, xâm phạm quyền riêng tư của người khác, thù địch, hoặc phân
            biệt chủng tộc, sắc tộc hoặc các hành động đáng lên án khác;
          </li>
          <li>
            sử dụng Các Dịch Vụ để gây hại cho người dùng khác dưới bất kỳ hình
            thức nào;
          </li>
          <li>
            sử dụng Các Dịch Vụ để mạo danh bất kỳ cá nhân hay tổ chức nào;
          </li>
          <li>
            hoặc giả mạo khác việc bạn liên kết với một cá nhân hay tổ chức;
          </li>
          <li>
            giả mạo các đầu đề hoặc thao túng khác đối với các yếu tố danh định
            nhằm che giấu nguồn gốc của bất kỳ Nội Dung nào được gửi qua Các
            Dịch Vụ;
          </li>
          <li>xóa gỡ bất kỳ thông báo độc quyền nào ra khỏi Phần mềm;</li>
          <li>
            thực hiện, cho phép hoặc ủy quyền sửa đổi, tạo ra các bản phái sinh,
            hoặc biên dịch Các Dịch Vụ mà không được sự cho phép của Chúng tôi;
          </li>
          <li>
            sử dụng Các Dịch Vụ vì bất kỳ mục đích thương mại nào hay vì lợi ích
            của bất kỳ bên thứ ba hoặc theo bất kỳ hình thức nào không được phép
            theo các quyền đã được cấp.
          </li>
          <li>sử dụng Các Dịch Vụ vì mục đích gian lận;</li>
          <li>
            tìm cách biên dịch giải mã, thay đổi cấu trúc kỹ thuật, tháo rời
            hoặc tấn công Các Dịch Vụ (hoặc bất kỳ phần nào của nó), hoặc thủ
            tiêu hay vượt qua bất kỳ công nghệ mã hóa hay biện pháp bảo mật nào
            mà Chúng tôi thực hiện liên quan đến Các Dịch Vụ và/hoặc dữ liệu
            được gửi, xử lý hoặc lưu trữ bởi Phần mềm;
          </li>
          <li>
            thu thập bất kỳ thông tin nào về các chủ Tài Khoản khác, bao gồm,
            nhưng không giới hạn các dữ liệu hay thông tin cá nhân;
          </li>
          <li>
            tải lên, đăng, gửi email, gửi hoặc cung cấp dưới hình thức khác bất
            kỳ Nội Dung nào mà bạn không có quyền cung cấp theo bất kỳ điều luật
            nào hay theo các quan hệ hợp đồng hoặc ủy thác;
          </li>
          <li>
            tải lên, đăng, gửi email, gửi hoặc cung cấp dưới hình thức khác bất
            kỳ Nội Dung nào xâm phạm bất kỳ bằng sáng chế, thương hiệu, lợi ích
            kinh doanh, bí mật thương mại, bản quyền hay độc quyền khác của bất
            kỳ bên nào;
          </li>
          <li>
            tải lên, đăng, gửi email, gửi hoặc cung cấp dưới hình thức khác bất
            kỳ tài liệu quảng cáo, quảng bá không được phép, “thư rác,” “thư
            hàng loạt,” “thư dây chuyền,”, “quảng cáo đa cấp” hoặc bất kỳ hình
            thức câu kéo khác;
          </li>
          <li>
            tải lên, đăng, gửi email, gửi hoặc cung cấp dưới hình thức khác bất
            kỳ tài liệu nào chứa virus, sâu, Trojan hoặc bất kỳ mã máy tính,
            trình tự, tập tin hay chương trình nào được thiết kế để trực tiếp
            hoặc gián tiếp cản trở, thao túng, làm gián đoạn, phá hủy hoặc hạn
            chế chức năng hay tính toàn vẹn của bất kỳ phần mềm hay phần cứng
            máy tính nào hay dữ liệu hoặc thiết bị viễn thông nào;
          </li>
          <li>
            làm rối loạn đến mạch đối thoại bình thường, làm cho màn hình “cuộn”
            nhanh hơn mức người dùng Các Dịch Vụ có thể đánh máy, hoặc hành động
            khác theo cách ảnh hưởng tiêu cực đến khả năng tham gia trao đổi
            trong thời gian thực của người dùng khác.
          </li>
          <li>
            làm gián đoạn, thao túng hoặc làm rối loạn Các Dịch Vụ hoặc các máy
            chủ hay mạng được kết nối với Các Dịch Vụ hoặc việc sử dụng Các Dịch
            Vụ của bất kỳ người dùng nào khác, hoặc không tuân thủ bất kỳ yêu
            cầu, thủ tục, chính sách hay quy định nào của các mạng được kết nối
            với Phần mềm;
          </li>
          <li>
            có bất kỳ hành động nào hay liên quan đến bất kỳ hành vi nào có thể
            trực tiếp hay gián tiếp làm thiệt hại, vô hiệu, gây áp lực quá mức,
            hoặc làm suy giảm Các Dịch Vụ hoặc các dịch vụ hay các mạng được kết
            nối với Các Dịch Vụ;
          </li>
          <li>
            sử dụng Các Dịch Vụ để xâm phạm quyền riêng tư của người khác hoặc
            để “lén theo dõi” hoặc quấy rối một người khác và/hoặc
          </li>
          <li>
            sử dụng Các Dịch Vụ để thu thập hoặc lưu lại dữ liệu cá nhân về
            người dùng khác liên quan đến hành vi và các hoạt động bị cấm bên
            trên.
          </li>
        </ul>
      </p>

      <p>
        Bạn hiểu rằng mọi Nội Dung, cho dù được đăng công khai hay gửi riêng tư,
        là do người gửi Nội Dung đó hoàn toàn chịu trách nhiệm. Điều này có
        nghĩa là bạn, chứ không phải Chúng tôi, hoàn toàn chịu trách nhiệm đối
        với mọi Nội Dung bạn tải lên, đăng, gửi email, gửi hoặc cung cấp theo
        hình thức khác thông qua Phần mềm.
      </p>

      <p>
        Bạn xác nhận rằng Chúng tôi có thể hoặc không sàng lọc trước Nội Dung
        nhưng Chúng tôi và những người được chỉ định sẽ có toàn quyền (nhưng
        không phải là nghĩa vụ) sàng lọc trước, từ chối, xóa hay gỡ bỏ bất kỳ
        Nội Dung nào được đăng trên Phần mềm. Không giới hạn các quy định trên,
        Chúng tôi và những người được chỉ định sẽ có quyền gỡ bỏ bất kỳ Nội Dung
        nào vi phạm các Điều Khoản Dịch Vụ này, nếu chúng tôi nhận được khiếu
        nại từ một người dùng khác, nếu chúng tôi nhận được thông báo về sự xâm
        phạm tài sản trí tuệ hoặc yêu cầu có giá trị pháp lý khác đề nghị gỡ bỏ,
        hoặc nếu Nội Dung đó là đáng lên án theo cách khác. Chúng tôi cũng có
        thể chặn đường gửi một nội dung liên lạc (bao gồm nhưng không giới hạn
        cập nhật thông tin, hoặc gửi câu hỏi thắc mắc) đến hoặc từ Các Dịch Vụ
        như một phần trong nỗ lực của chúng tôi để bảo vệ Các Dịch Vụ hoặc người
        dùng của chúng tôi, hoặc để thực thi các điều khoản trong các Điều Khoản
        và Điều Kiện này.
      </p>

      <p>
        Bạn xác nhận và đồng ý rằng Chúng tôi có thể truy cập, bảo lưu và tiết
        lộ thông tin tài khoản của bạn và Nội Dung của bạn nếu luật quy định
        điều đó hoặc tuân theo lệnh của tòa hoặc theo yêu cầu của bất kỳ cơ quan
        chính phủ hay cơ quan quản lý nào có thẩm quyền đối với Chúng tôi hoặc
        vì thiện chí cho rằng sự bảo lưu truy cập hay tiết lộ đó là cần thiết
        một cách hợp lý để:
        <ol>
          <li> tuân thủ quy trình pháp lý; </li>
          <li>thực thi các Điều Khoản Dịch Vụ này;</li>
          <li>
            phản hồi một đơn khiếu kiện cho rằng Nội Dung vi phạm quyền của các
            bên thứ ba;
          </li>
          <li>
            phản hồi yêu cầu của bạn đối với dịch vụ chăm sóc khách hàng; hoặc
          </li>
          <li>
            bảo vệ quyền lợi, tài sản hoặc sự an toàn cá nhân của Chúng tôi,
            người dùng của Chúng tôi và/hoặc công chúng.
          </li>
        </ol>
      </p>

      <h2 id="violation-terms">11. Vi phạm các điều khoản dịch vụ</h2>
      <p>
        Nếu bạn cho rằng một người dùng trên Phần mềm của chúng tôi đang vi phạm
        các Điều Khoản Dịch Vụ này, vui lòng liên hệ với chúng tôi qua email
        cvhtechnology@gmail.com.
      </p>

      <h2 id="ensure">12. Đảm bảo và cam đoan của người dùng</h2>
      <p>
        Bạn đảm bảo và cam đoan rằng:
        <ul>
          <li>
            bạn có năng lực pháp lý (và trong trường hợp là trẻ vị thành niên,
            có sự đồng ý hợp lệ của cha mẹ hoặc người giám hộ hợp pháp), có
            quyền và khả năng ký kết các Điều Khoản Dịch Vụ này và tuân thủ các
            điều khoản của nó;
          </li>
          <li>
            bạn sẽ chỉ sử dụng Các Dịch Vụ cho các mục đích hợp pháp và tuân
            theo các Điều Khoản Dịch Vụ này và mọi điều luật, quy định, quy tắc,
            chỉ thị, hướng dẫn, chính sách và quy định hiện hành; và
          </li>
          <li>
            bạn sẽ chỉ sử dụng Các Dịch Vụ cho mục đích sử dụng cá nhân từ nhu
            cầu thực sự của bản thân, hoặc được sự cho phép và ủy quyền của
            người nào đó để thao tác đăng ký sử dụng dịch vụ giúp cho người đó.
          </li>
        </ul>
      </p>

      <h2 id="validity">13. Tính có hiệu lực theo từng phần</h2>
      <p>
        Nếu bất kỳ quy định nào của các Điều Khoản Dịch Vụ này bị xem là trái
        luật, vô hiệu, hoặc không thể thực thi vì bất kỳ lý do gì theo luật pháp
        của bất kỳ khu vực nào, thì quy định đó sẽ được xem là tách biệt với các
        điều khoản và điều kiện này và sẽ không ảnh hưởng đến tính hợp lệ và khả
        năng thực thi của bất kỳ quy định nào còn lại trong khu vực đó hoặc tính
        hợp lệ hay khả năng thực thi của quy định đang bàn tới theo luật pháp
        của bất kỳ khu vực nào khác.
      </p>

      <h2 id="law-control">14. Luật điều chỉnh</h2>
      <p>
        Các Điều Khoản Dịch Vụ này sẽ được điều chỉnh bởi và được giải thích
        theo luật pháp Cộng Hòa Xã Hội Chủ Nghĩa Việt Nam.
      </p>
      <h2 id="general-terms">15. Các quy định chung</h2>
      <p>
        Chúng tôi bảo lưu tất cả các quyền không được cấp rõ trong đây.
        <ul>
          <li>
            Chúng tôi có thể điều chỉnh các Điều Khoản Dịch Vụ này vào bất kỳ
            lúc nào bằng cách đăng các Điều Khoản Dịch Vụ đã chỉnh sửa lên Phần
            mềm này. Việc bạn tiếp tục sử dụng Phần mềm này sau khi những thay
            đổi đó đã được đăng sẽ cấu thành việc bạn chấp nhận các Điều Khoản
            Dịch Vụ đã chỉnh sửa đó.
          </li>
          <li>
            Bạn không được chuyển nhượng, cấp phép con hay chuyển giao bất kỳ
            quyền nào đã cấp cho bạn theo đây hoặc lập hợp đồng phụ bất kỳ nghĩa
            vụ nào của bạn.
          </li>
          <li>
            Không nội dung nào trong các Điều Khoản Dịch Vụ này sẽ cấu thành sự
            hợp tác, liên doanh hay quan hệ người ủy thác - đại lý giữa bạn và
            Chúng tôi, cũng không cho phép bạn chịu bất kỳ chi phí hay trách
            nhiệm pháp lý nào thay mặt Chúng tôi.
          </li>
        </ul>
      </p>
      <p>
        Điều Khoản Dịch Vụ này là một phần không tách rời với Chính Sách Bảo Mật
        của chúng tôi. Xin vui lòng đọc thêm các Chính Sách Bảo Mật để hiểu rõ
        các quy định, điều khoản, chính sách và hướng dẫn của chúng tôi trước
        khi bạn truy cập Phần mềm và/hoặc đăng ký và/hoặc sử dụng các Dịch Vụ
        trên Phần mềm của chúng tôi.
      </p>
      <p>
        TÔI ĐÃ ĐỌC THỎA THUẬN NÀY VÀ ĐỒNG Ý VỚI TẤT CẢ CÁC QUY ĐỊNH CÓ TRONG NỘI
        DUNG BÊN TRÊN VÀ BẤT KỲ BẢN CHỈNH SỬA NÀO CỦA NỘI DUNG BÊN TRÊN SAU NÀY.
        BẰNG VIỆC TIẾP TỤC TRUY CẬP VÀ SỬ DỤNG PHẦN MỀM, TÔI HIỂU RẰNG TÔI ĐANG
        TẠO RA MỘT CHỮ KÝ ĐIỆN TỬ MÀ NÓ CÓ GIÁ TRỊ VÀ HIỆU LỰC TƯƠNG TỰ NHƯ CHỮ
        KÝ TÔI KÝ BẰNG TAY.
      </p>
    </div>
  );
}

export default TermsOfServices;
