import { Col, Collapse, Row } from "antd";
import React from "react";
import GlassPanel from "./GlassPanel";
import WIDTH_BREAK from "../../constants";

const Tag = ({ description, type }) => {
  return (
    <div className={`${type !== "soon" ? "ready" : "soon"} tag`}>
      {description}
    </div>
  );
};
const Product = ({ props, scrollTo }) => {
  const { id, name, des, det, note, ill, cls } = props;
  const { Panel } = Collapse;
  const tableContent = [
    {
      func: "Tính năng",
      desc: "Mô tả cụ thể",
      time: "Thời gian phản hồi trung bình",
      release: "Dự kiến ra mắt",
    },
    {
      func: "Tạo bài luận",
      desc: "Viết luận theo yêu cầu và được tối ưu hóa cho cá nhân người dùng",
      time: "10s",
      release: <Tag description="Sẵn sàng" />,
    },
    {
      func: "Sửa luận tổng quát",
      desc: " Sửa bài luận chi tiết và giải thích kỹ từng vị trí cần được cải thiện và cách khắc phục",
      time: "12s",
      release: <Tag description="Sẵn sàng" />,
    },
    {
      func: "Sửa luận IELTS Task 1/2",
      desc: (
        <ul style={{ paddingLeft: "16px", margin: 0 }}>
          <li>
            Chấm điểm theo band, sửa theo các tiêu chí tiêu chuẩn của IELTS.
          </li>
          <li>
            Giải thích kĩ cách chấm ( nêu ra lý do vì sao bạn đạt được điểm số
            này và những lỗi sai bạn mắc phải cũng như cách khắc phục ).
          </li>
          <li>
            Giới thiệu các từ vựng và các cụm từ nâng cao để cải thiện bài viết.
          </li>
        </ul>
      ),
      time: "20s",
      release: <Tag description="Sẵn sàng" />,
    },
    {
      func: "Lập dàn ý chi tiết cho bài viết/bài nói IELTS",
      desc: (
        <ul style={{ paddingLeft: "16px", margin: 0 }}>
          <li>Lập dàn ý hướng dẫn kĩ các bước, có ví dụ.</li>
          <li>
            Giới thiệu các từ vựng và các cụm từ nâng cao liên quan đến chủ đề
            bài viết.
          </li>
        </ul>
      ),
      time: "20s",
      release: <Tag description="Sẵn sàng" />,
    },
    {
      func: "Tạo bài nói/bài luận IELTS mẫu",
      desc: "Viết bài mẫu để người dùng so sánh giữa band 5.5+ và band 7.5+ (bài viết được mô phỏng như người đi thi thật)",
      time: "10s",
      release: <Tag description="Sắp ra mắt" type="soon" />,
    },
    {
      func: "Phân tích bài đọc chuyên sâu",
      desc: (
        <ul style={{ paddingLeft: "16px", margin: 0 }}>
          <li>
            Giúp người dùng trả lời và phân tích 1 section của bài thi IELTS
            readings.
          </li>
          <li>
            Có thể phân tích mọi dạng câu hỏi và câu trả lời. Giúp người dùng
            hiểu và tư duy về bài đọc một cách toàn diện.
          </li>
        </ul>
      ),
      time: "15s",
      release: <Tag description="Sắp ra mắt" type="soon" />,
    },
    {
      func: "Chấm điểm bài nói",
      desc: (
        <ul style={{ paddingLeft: "16px", margin: 0 }}>
          <li>
            Người đọc sẽ ghi âm và được chấm điểm theo các tiêu chí của IELTS
            speaking.
          </li>
          <li>
            Chỉ ra các lỗi chi tiết, cách khắc phục, và giới thiệu các từ và cụm
            từ để cải thiện bài nói.
          </li>
        </ul>
      ),
      time: "20s",
      release: <Tag description="Sắp ra mắt" type="soon" />,
    },
  ];

  return (
    <div className="product" id={id}>
      <div className="product-basic">
        <div className="product-name">
          <h1 className={`product-title product-title-${cls}`}>{name}</h1>
          <p className={`product-des product-des-${cls}`}>{des}</p>
          {id === "Essay" ? (
            <>
              <a
                className={`explore-button explore-button-${cls}`}
                href="https://ezbro.io"
                rel="noreferrer"
                target="_blank"
                style={{ marginRight: "16px" }}
              >
                Trải nghiệm
              </a>

              <button
                className="explore-button secondary-button ezbro-table-button"
                onClick={() =>
                  window.scrollTo({
                    top: scrollTo + window.innerHeight,
                    behavior: "smooth",
                  })
                }
              >
                Chi tiết
              </button>
            </>
          ) : null}
        </div>

        {id === "Med" && (
          <Row className={`product-feat product-feat-${cls}`} gutter={[16, 16]}>
            <Row gutter={[16, 16]}>
              <Col xs={24} xl={12}>
                <GlassPanel ill={ill[0]} det={det[0]} note={note[0]} />
              </Col>
              <Col xs={24} xl={12}>
                <GlassPanel ill={ill[1]} det={det[1]} note={note[1]} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} xl={12}>
                <GlassPanel ill={ill[3]} det={det[3]} note={note[3]} />
              </Col>
              <Col xs={24} xl={12}>
                <GlassPanel ill={ill[4]} det={det[4]} note={note[4]} />
              </Col>
            </Row>
          </Row>
        )}
      </div>

      <div className="service-information">
        {id === "Med" ? null : (
          <>
            {window.innerWidth > WIDTH_BREAK ? (
              <table
                className="service-information-table glass-panel"
                id="ezbro-table"
              >
                <thead>
                  <tr>
                    <th>{tableContent[0].func}</th>
                    <th>{tableContent[0].desc}</th>
                    <th>{tableContent[0].time}</th>
                    <th>{tableContent[0].release}</th>
                  </tr>
                </thead>
                <tbody>
                  {tableContent.map((row, rowI) => {
                    if (rowI > 0) {
                      return (
                        <tr key={`feature-row-${rowI}`}>
                          <td>{row.func}</td>
                          <td>{row.desc}</td>
                          <td>{row.time}</td>
                          <td>{row.release}</td>
                        </tr>
                      );
                    }
                    return null;
                  })}
                </tbody>
              </table>
            ) : (
              <>
                <h2 className={`product-title product-title-${cls}`}>
                  Tính năng
                </h2>
                <Collapse className="glass-panel" accordion={true}>
                  {tableContent.map((row, rowI) => {
                    if (rowI > 0) {
                      return (
                        <Panel
                          key={`feature-panel-${rowI}`}
                          className="panel-table-row"
                          header={
                            <div className="panel-header-config">
                              <span style={{ width: "70%" }}>{row.func}</span>
                              <span className="panel-header-config">
                                {row.release}
                              </span>
                            </div>
                          }
                        >
                          <td>{row.desc}</td>
                        </Panel>
                      );
                    }
                    return null;
                  })}
                </Collapse>
              </>
            )}
            <p className="service-information-note">
              Có thể mua thêm lượt sử dụng ở các tính năng{" "}
            </p>
          </>
        )}
      </div>
    </div>
  );
};
export default Product;
