import { Helmet } from "react-helmet";

export const ProductInspectionPolicy = () => {
  return (
    <main className="privacy-content" id="ProductInspectionPolicy">
      <Helmet>Chính sách kiểm hàng</Helmet>
      <h1>Chính sách kiểm hàng</h1>
      <p>Các dịch vụ đúng với mô tả chi tiết trên web.</p>
      <p>
        Sau khi mua EZBRO và thanh toán thành công, Người dùng sẽ nhận được
        thông báo của Công ty qua email về số điểm EZBRO đã mua thành công.
        Người dùng cần kiểm tra chính xác thông tin tài khoản sau khi đã mua
        EZBRO
      </p>
      <p>
        Nếu có vấn đề số lượng EZBRO chưa được áp dụng vào tài khoản, Người dùng
        gọi ngay đến Hotline 0913 963 938 để được hỗ trợ nhanh nhất.
      </p>
      <p>
        Để biết thêm thông tin chi tiết, xin vui lòng liên hệ với chúng tôi qua
        hotline: 0913 963 938 hoặc địa chỉ email: cvhtechnology@gmail.com.
      </p>
    </main>
  );
};
