import gsap from 'gsap'
import React, { useEffect, useRef } from 'react'

/// Generates a background for the whole app
/// og inspiration from https://css-tricks.com/an-interactive-starry-backdrop-for-content/
///
const StarBG = ({ densityRatio = 0.6, sizeLimit = 8, defaultAlpha = 0.2, scaleLimit = 10, proximityRatio = 0.5 }) => {
    const canvasRef = useRef(null)
    const contextRef = useRef(null)
    const starsRef = useRef(null)
    const vminRef = useRef(null)
    const scaleMapperRef = useRef(null)
    const alphaMapperRef = useRef(null)

    useEffect(() => {
        contextRef.current = canvasRef.current.getContext('2d')
        const LOAD = () => {
            vminRef.current = Math.min(document.body.scrollHeight, window.innerWidth)
            const star_no = Math.floor(vminRef.current * densityRatio) // # of starts from density and 1 edge
            //get a map function (mapper) of scale and alpha vals depending on the input values (im, iM, om, oM)
            //so basically alpha 0->1 corresponds to size 0 to maxWidth
            scaleMapperRef.current = gsap.utils.mapRange(0, vminRef.current * proximityRatio, scaleLimit, 1);
            alphaMapperRef.current = gsap.utils.mapRange(0, vminRef.current * proximityRatio, 1, defaultAlpha);
            canvasRef.current.width = window.innerWidth;
            canvasRef.current.height = document.body.scrollHeight;
            /// generating the stars
            starsRef.current = new Array(star_no).fill().map(() => ({
                x: gsap.utils.random(0, window.innerWidth, 1),
                y: gsap.utils.random(0, document.body.scrollHeight, 1),
                size: gsap.utils.random(1, sizeLimit, 1),
                scale: 1,
                alpha: gsap.utils.random(0.1, defaultAlpha, 0.1),
            }))
        }
        const RENDER = () => {
            /// clearing the canvas
            contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
            ///render the stars onto the canvas
            starsRef.current.forEach(star => {
                contextRef.current.fillStyle = `hsla(0, 100%, 100%, ${star.alpha})`
                contextRef.current.beginPath()
                contextRef.current.roundRect(star.x, star.y, star.size, star.size, 2)
                contextRef.current.fill()
            })
        }

        LOAD()
        gsap.ticker.add(RENDER)

        // Set up event handling
        window.addEventListener('resize', LOAD)

        return () => {
            window.removeEventListener('resize', LOAD)
            gsap.ticker.remove(RENDER)
        }

    }, [defaultAlpha, densityRatio, proximityRatio, scaleLimit, sizeLimit])
    return <canvas ref={canvasRef} className="starry-bg" />
}

export default StarBG;