import { Helmet } from "react-helmet";

export const ComplaintsProcedure = () => {
  return (
    <main className="privacy-content" id="ComplaintsProcedure">
      <Helmet>Quy trình tiếp nhận và giải quyết khiếu nại</Helmet>
      <h1>Quy trình tiếp nhận và giải quyết khiếu nại</h1>
      <h2>Điều 1: Cơ chế giải quyết</h2>
      <p>
        Mọi khiếu nại từ phía Khách hàng đều được giải quyết trên cơ sở thương
        lượng. Trường hợp không đạt được thỏa thuận như mong muốn, một trong hai
        bên có quyền đưa vụ việc ra Cơ quan nhà nước có thẩm quyền để giải
        quyết.
      </p>
      <p>
        Trong trường hợp xảy ra sự cố do lỗi của Công ty Cổ phần Công nghệ Sức
        khỏe Tiện lợi, chúng tôi sẽ ngay lập tức áp dụng các biện pháp cần thiết
        để đảm bảo quyền lợi cho khách hàng.
      </p>
      <h2>Điều 2: Phương thức gửi khiếu nại</h2>
      <p>
        Khách hàng có thể gửi khiếu nại để yêu cầu Công ty Cổ phần Công nghệ Sức
        khỏe Tiện lợi giải quyết bằng cách thức sau:
      </p>
      <ul>
        <li>Cách 1: Gọi điện thoại tới hotline của chúng tôi: 0913 963 938</li>
        <li>Cách 2: Gửi email tới địa chỉ: cvhtechnology@gmail.com</li>
        <li>
          Cách 3: Trực tiếp tới địa chỉ trụ sở của Công ty Cổ phần Công nghệ Sức
          khỏe Tiện lợi: 94/8 Đỗ Tấn Phong, Phường 09, Quận Phú Nhuận, Thành phố
          Hồ Chí Minh, Việt Nam.
        </li>
      </ul>
      <p>Thời gian làm việc: Thứ 2 - Thứ 7, từ 08:00 - 20:00.</p>
      <h2>Điều 3: Trình tự thực hiện</h2>
      <i>Bước 1: Gửi khiếu nại</i>
      <ul>
        Khách hàng gửi khiếu nại về dịch vụ hoặc quyền lợi chưa được đảm bảo đầy
        đủ tới Công ty Cổ phần Công nghệ Sức khỏe Tiện lợi qua các cách thức đã
        quy định ở trên.
      </ul>

      <i>Tiếp nhận và xử lý khiếu nại</i>
      <ul>
        Công ty Cổ phần Công nghệ Sức khỏe Tiện lợi sẽ tiếp nhận các khiếu nại
        của Khách hàng và tiến hành xác minh thông tin.
      </ul>

      <i>Bước 3: Phản hồi tới khách hàng</i>
      <ul>
        Công ty Cổ phần Công nghệ Sức khỏe Tiện lợi sẽ phản hồi kết quả xử lý
        khiếu nại tới khách hàng trong thời hạn 05 ngày làm việc, kể từ ngày
        việc xác minh, xử lý thông tin được hoàn thành.
      </ul>
      <p>
        Công ty Cổ phần Công nghệ Sức khỏe Tiện lợi sẽ cố gắng xác minh thông
        tin và giải quyết khiếu nại một cách nhanh chóng, kịp thời để đảm bảo
        quyền lợi cho Khách hàng. Trường hợp nằm ngoài khả năng và thẩm quyền
        của Công ty Cổ phần Công nghệ Sức khỏe Tiện lợi, chúng tôi sẽ yêu cầu
        Khách hàng đưa vụ việc này ra cơ quan nhà nước có thẩm quyền giải quyết
        theo pháp luật.
      </p>
    </main>
  );
};
