import React, { useState } from "react";
import logo from "../../styles/img/logo_dark.png";
import WIDTH_BREAK from "../../constants";
function Nav({ section, abt, med, essay, width }) {
  const [openMenu, setOpenMenu] = useState(false);

  /// there would be 2 different nav for screen size > 1200px and vice versa, normal nav
  /// and hamburger menu
  return (
    <>
      <div className="nav">
        <div className="nav-wrapper">
          <img src={logo} alt="brand logo" />
          {width > WIDTH_BREAK ? (
            <nav>
              <a
                href="#null"
                className={section === "Home" ? "active" : ""}
                onClick={(e) => {
                  e.preventDefault();
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                Trang chủ
              </a>
              <a
                href="#null"
                className={section === "About" ? "active" : ""}
                onClick={(e) => {
                  e.preventDefault();
                  window.scrollTo({ top: abt, behavior: "smooth" });
                }}
              >
                Về CVH
              </a>
              <a
                href="#null"
                className={section === "Essay" ? "active" : ""}
                onClick={(e) => {
                  e.preventDefault();
                  window.scrollTo({ top: essay, behavior: "smooth" });
                }}
              >
                Edu Tech
              </a>
              <a
                href="#null"
                className={section === "Med" ? "active" : ""}
                onClick={(e) => {
                  e.preventDefault();
                  window.scrollTo({ top: med, behavior: "smooth" });
                }}
              >
                Med Tech
              </a>

              <a
                href="#null"
                className={section === "Foo" ? "active" : ""}
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .getElementById("Footer")
                    .scrollIntoView({ behavior: "smooth" });
                }}
              >
                Chính sách
              </a>
            </nav>
          ) : (
            <div className="nav-select">
              <button
                className="drawer-button"
                onClick={() => {
                  setOpenMenu((prev) => !prev);
                }}
              >
                <div className="stick-figure">
                  <div
                    className={openMenu ? "stick mid open" : "stick mid"}
                  ></div>
                  <div
                    className={openMenu ? "stick top open" : "stick top "}
                  ></div>
                  <div
                    className={openMenu ? "stick bottom open" : "stick bottom"}
                  ></div>
                </div>
              </button>
            </div>
          )}
        </div>

        {openMenu ? (
          <div className="menu">
            <nav>
              <a
                href="#null"
                className="active"
                onClick={(e) => {
                  e.preventDefault();
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  setOpenMenu(false);
                }}
              >
                Trang chủ
              </a>
              <a
                href="#null"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  window.scrollTo({ top: abt, behavior: "smooth" });
                  setOpenMenu(false);
                }}
              >
                Về CVH
              </a>
              <a
                href="#null"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  window.scrollTo({ top: essay, behavior: "smooth" });
                  setOpenMenu(false);
                }}
              >
                Edu Tech
              </a>
              <a
                href="#null"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  window.scrollTo({ top: med, behavior: "smooth" });
                  setOpenMenu(false);
                }}
              >
                Med Tech
              </a>

              <a
                href="#null"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .getElementById("Footer")
                    .scrollIntoView({ behavior: "smooth" });
                  setOpenMenu(false);
                }}
              >
                Chính sách
              </a>
            </nav>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default Nav;
