import { Helmet } from "react-helmet";

export const RefundPolicy = () => {
  return (
    <main className="privacy-content" id="RefundPolicy">
      <Helmet>Chính sách đổi trả, hoàn tiền</Helmet>
      <h1>Chính sách đổi trả, hoàn tiền</h1>
      <h2>1. ĐIỀU KIỆN ĐỔI TRẢ</h2>
      <p>
        <u>Các trường hợp nhận đổi trả:</u>
      </p>
      <ul>
        <li>
          Các lỗi kỹ thuật liên quan đến việc truy cập vào tài khoản do lỗi của
          cvhtechnology.com.
        </li>
        <li>Các lỗi thanh toán: Thanh toán thừa, thanh toán trùng đơn hàng.</li>
      </ul>
      <br />
      <p>
        <u>Các trường hợp không áp dụng đổi trả:</u>
        <ul>
          <p>Các trường hợp với lý do xuất phát từ người dùng:</p>
          <li>
            Người dùng không muốn sử dụng tài khoản mà không có lý do chính
            đáng.
          </li>
          <li>Học viên chưa đọc kỹ thông tin về dịch vụ.</li>
        </ul>
      </p>

      <h2>2. QUY ĐỊNH VỀ ĐỔI TRẢ SẢN PHẨM</h2>
      <p>
        <u>Thời gian gửi chuyển trả sản phẩm: </u>trong vòng 07 ngày kể từ ngày
        học viên mua điểm EZBRO.
      </p>
      <p>
        <u>Cách thức đổi trả: </u>Người dùng cần phải thông báo cho nhân viên
        của Công ty Cổ phần Công nghệ Sức khỏe Tiện lợi (qua hotline: 0913 963
        938) về lý do đổi trả và số điện thoại liên lạc chính xác để chúng tôi
        có thể thực hiện quy trình đổi trả sản phẩm một cách nhanh chóng nhất
        theo yêu cầu của Người dùng.
      </p>
      <h2>3. PHƯƠNG THỨC HOÀN TIỀN</h2>
      <p>
        Trong vòng 48 giờ kể từ thời điểm chúng tôi nhận được thông báo yêu cầu
        đổi trả, nhân viên chăm sóc khách hàng của Công ty Cổ phần Công nghệ Sức
        khỏe Tiện lợi sẽ liên hệ với khách hàng để xác nhận thông tin thanh toán
        phục vụ cho việc hoàn lại tiền.
      </p>
      <p>
        Việc hoàn tiền sẽ được chúng tôi tiến hành bằng phương thức{" "}
        <b>chuyển khoản</b> vào tài khoản ngân hàng của Quý khách.
      </p>
    </main>
  );
};
