import React from 'react';
import aiicon from '../../styles/img/AI_icon.png';
function About({ scrollTo }) {
    return (
        <div className="section" id="About">
            <div className="section-left about-left">
                <div className="section-intro">
                    <h1 className="intro-one-about">Về CVH Technology</h1>
                </div>
                <div className="about-content">
                    <div className="titles about-titles">
                        <div className="ai-icon-wrapper">
                            <img src={aiicon} alt="ai icon" className="ai-icon" />
                        </div>

                        <div className="title-parent">
                            <h2>
                                Một công ty kỹ sư công nghệ trẻ
                                với mong muốn cống hiến
                                cho ngành giáo dục và y tế
                                ở Việt Nam bằng trí tuệ nhân tạo
                            </h2>
                        </div>
                        <div className="title-child">
                            <p>
                                Chúng tôi phát triển công nghệ AI để
                                hỗ trợ cho việc học tập được tiện lợi,
                                và việc nhận được thông tin tư vấn y tế
                                và đặt khám được chủ động và nhanh chóng hơn.
                            </p>
                        </div>
                        <div className="button-wrapper-abbout">
                            <button className="explore-button about-button"
                                onClick={() => window.scrollTo({ top: scrollTo, behavior: "smooth", })}
                            >
                                Dịch vụ
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;