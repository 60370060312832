import { Collapse } from "antd";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import WIDTH_BREAK from "../../constants";
function Footer({ width }) {
  const currYear = new Date().getFullYear();
  const { Panel } = Collapse;
  function CompanyLinks() {
    return (
      <ul>
        <li>
          <Link to="/usage-instruction" target="_blank">
            Hướng dẫn mua hàng trực tuyến
          </Link>
        </li>
        <li>
          <Link to="/payment-policy" target="_blank">
            Chính sách thanh toán
          </Link>
        </li>
        <li>
          <Link to="/product-inspection-policy" target="_blank">
            Chính sách kiểm hàng
          </Link>
        </li>
        <li>
          <Link to="/privacy-policy" target="_blank">
            Chính sách bảo mật
          </Link>
        </li>
        <li>
          <Link to="/delivery-policy" target="_blank">
            Chính sách vận chuyển, giao nhận
          </Link>
        </li>
        <li>
          <Link to="/complaints-procedure" target="_blank">
            Quy trình tiếp nhận và giải quyết khiếu nại
          </Link>
        </li>
        <li>
          <Link to="/refund-policy" target="_blank">
            Chính sách đổi trả, hoàn tiền
          </Link>
        </li>
        {/* <li>
          <Link to="/terms-of-services" target="_blank">
            Điều khoản sử dụng
          </Link>
        </li>
        <li>
          <Link to="/services-policy" target="_blank">
            Chính sách dịch vụ
          </Link>
        </li> */}
      </ul>
    );
  }

  function AppLinks() {
    return (
      <ul>
        <li>
          <a href="https://ezbro.io" rel="noreferrer" target="_blank">
            Edu Tech
          </a>
        </li>
        <li>
          <a href="#Med" className="dimmed">
            Med Tech
          </a>
        </li>
      </ul>
    );
  }

  function ContLinks() {
    return (
      <ul>
        {/* <li>
          <a href="mit-info">Thông tin chủ sở hữu</a>
        </li> */}
        <li>
          <p>CÔNG TY CỔ PHẦN CÔNG NGHỆ SỨC KHỎE TIỆN LỢI</p>
        </li>
        <li>
          <p>
            Mã số thuế: 0317643803 | Nơi cấp: Sở KHĐT TPHCM, ngày 11/01/2023.
          </p>
        </li>
        <li>
          <p>Hotline: 0913 963 938</p>
        </li>
        <li>
          <p>Email: cvhtechnology@gmail.com</p>
        </li>
        <li>
          <p>Địa chỉ: 94/8 Đỗ Tấn Phong, F9, quận Phú Nhuận, TP.HCM</p>
        </li>
      </ul>
    );
  }
  return (
    <footer id="Footer">
      {width > WIDTH_BREAK ? (
        <div className="footer-list">
          <div className="footer-comp">
            <h4>Công ty</h4>
            {CompanyLinks()}
          </div>

          <div className="footer-comp">
            <h4>Ứng dụng</h4>
            {AppLinks()}
          </div>

          <div className="footer-comp">
            <h4>Liên hệ</h4>
            {ContLinks()}
          </div>
        </div>
      ) : (
        <Collapse className="footer-list">
          <Panel className="footer-comp" header="Công ty">
            {CompanyLinks()}
          </Panel>

          <Panel className="footer-comp" header="Ứng dụng">
            {AppLinks()}
          </Panel>

          <Panel className="footer-comp" header="Liên hệ">
            {ContLinks()}
          </Panel>
        </Collapse>
      )}
      <div className="secondary-footer">
        <p className="copyright footer-comp">
          ©{currYear} Convenient Health Technology Corporation
        </p>
        <a href="http://online.gov.vn/Home/WebDetails/111551" className="verified-element">
          <img
            alt=""
            title=""
            src="https://webmedia.com.vn/images/2021/09/logo-da-thong-bao-bo-cong-thuong-mau-xanh.png"
          />
        </a>
        <a
          href="https://www.dmca.com/compliance/cvhtechnology.com"
          title="DMCA Compliance information for cvhtechnology.com"
          className="verified-element dmca"
        >
          <img
            src="https://www.dmca.com/img/dmca-compliant-grayscale.png"
            alt="DMCA compliant images"
          />
        </a>
      </div>

      <Outlet />
    </footer>
  );
}

export default Footer;
