import React from "react";
import Rocket from "../stylings/Rocket";
import WIDTH_BREAK from "./../../constants";
function Home({ scrollTo }) {
  return (
    <div className="section" id="Home">
      <div className="home-container">
        <div className="section-left">
          <div className="titles home-titles">
            <h1 className="home-titles-heading"> Sử dụng công nghệ </h1>
            <h1 className="home-titles-heading">
              {" "}
              Đem đến <span className="secondary-span">sự tiện lợi</span>{" "}
            </h1>
            <div className="button-wrapper">
              <button
                className="explore-button home-button cvh-button"
                data=""
                onClick={() =>
                  window.scrollTo({ top: scrollTo, behavior: "smooth" })
                }
              >
                Về CVH Technology
              </button>
              <button
                className="secondary-button explore-button"
                data=""
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .getElementById("Footer")
                    .scrollIntoView({ behavior: "smooth" });
                }}
              >
                Chính sách và điều khoản
              </button>
            </div>
          </div>
        </div>
        {window.innerWidth > WIDTH_BREAK && (
          <div className="home-promo-wrapper">
            <div className="rocket-illustration-wrapper">
              <div className="rocket-cloud-wrapper">
                <Rocket />
                <div className="clouds-wrapper">
                  <div className="cloud">IELTS</div>
                  <div className="cloud">Generate</div>
                  <div className="cloud">Essay</div>
                  <div className="cloud">Evaluate</div>
                </div>
              </div>

              <div className="illustration-description">
                <div className="cta-wrapper">
                  <h2 className="ezbro-ai-cloud">✨ EZBro-AI ✨</h2>
                  <a
                    className="explore-button ezbro-button"
                    href="https://ezbro.io"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Trải nghiệm ngay
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Home;
