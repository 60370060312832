import React from "react";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  return (
    <div className="privacy-content" id="Privacy-Policies-VN">
      <Helmet>
        <title>Chính sách bảo mật thông tin cá nhân</title>
      </Helmet>
      <h1>Chính sách bảo mật thông tin cá nhân</h1>
      <div className="table-of-contents">
        <a href="#CVHTech-principles">1. Mục đích thu thập thông tin:</a>
        <a href="#privacy-policy">2. Phạm vi thu thập thông tin:</a>
        <a href="#information-collection">3. Phạm vi sử dụng thông tin</a>
        <a href="#information-usage">4. Thời gian lưu trữ thông tin:</a>
        <a href="#information-sharing">
          5. Cam kết bảo mật thông tin cá nhân khách hàng:
        </a>
        <a href="#information-rm">
          6. Những người hoặc tổ chức có thể được tiếp cận với thông tin:
        </a>
        <a href="#security">
          7. Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân:
        </a>
        <a href="#terms">
          8. Phương tiện và công cụ để người dùng tiếp cận và chỉnh sửa dữ liệu
          cá nhân của mình.
        </a>
        <a href="#contacting">
          9. Cơ chế tiếp nhận và giải quyết khiếu nại của người tiêu dùng liên
          quan đến việc thông tin cá nhân bị sử dụng sai mục đích hoặc phạm vi
          đã thông báo
        </a>
      </div>
      <p>
        Nhằm đảm bảo an toàn cho website và bảo mật thông tin cho người tiêu
        dùng, chúng tôi đưa ra một số chính sách bảo mật thông tin cho khách
        hàng cá nhân và tổ chức khi mua hàng tại website:{" "}
        <a href="/">cvhtechnology.com</a> như sau:
      </p>
      <h2 id="CVHTech-principles">1. Mục đích thu thập thông tin</h2>
      <p>
        Việc thu thập dữ liệu của website: Cvhtechnology.com sẽ giúp chúng tôi:
      </p>
      <ul>
        <li>
          Nắm bắt được các yêu cầu, mong muốn của khách hàng nhằm nâng cao chất
          lượng sản phẩm.
        </li>
        <li>
          Giúp khách hàng cập nhật các thông tin chương trình khuyến mại, giảm
          giá do chúng tôi tổ chức một cách sớm nhất.
        </li>
        <li>
          Hỗ trợ khách hàng khi có khiếu nại, tranh chấp một cách nhanh nhất.
        </li>
      </ul>

      <h2 id="privacy-policy">2. Phạm vi thu thập thông tin</h2>
      <p>Cvhtechnology.com thu thập các thông tin sau của khách hàng:</p>
      <ul>
        <li>Họ và Tên</li>
        <li>Địa chỉ</li>
        <li>Điện thoại</li>
        <li>Địa chỉ email</li>
        <li>Nội dung cần liên hệ</li>
      </ul>

      <h2 id="information-collection">3. Phạm vi sử dụng thông tin</h2>
      <p>Thông tin thu thập được chúng tôi sử dụng cho các mục đích sau:</p>
      <ul>
        <li>Giao hàng cho quý khách đã mua hàng tại Cvhtechnology.com;</li>
        <li>Thông báo về việc giao hàng và hỗ trợ cho khách hàng;</li>
        <li>
          Xử lý các đơn đặt hàng và cung cấp sản phẩm thông qua website:
          Cvhtechnology.com.
        </li>
      </ul>
      <br />
      <p>
        Ngoài ra, các thông tin giao dịch gồm: lịch sử mua hàng, giá trị giao
        dịch, phương thức vận chuyển và thanh toán cũng được website:
        Cvhtechnology.com lưu trữ nhằm giải quyết những vấn đề có thể phát sinh
        về sau.
      </p>
      <p>
        Chúng tôi sẽ không chia sẻ thông tin khách hàng cho các bên thứ 3, trừ
        những trường hợp cụ thể sau đây:
      </p>
      <ul>
        <li>
          Để bảo vệ Cvhtechnology.com và các bên thứ ba khác: Chúng tôi chỉ đưa
          ra thông tin tài khoản và những thông tin cá nhân khác khi tin chắc
          rằng việc đưa những thông tin đó là phù hợp với luật pháp, bảo vệ
          quyền lợi, tài sản của người sử dụng dịch vụ, của Cvhtechnology.com và
          các bên thứ ba khác.
        </li>
        <li>
          Theo yêu cầu từ phía cơ quan nhà nước hoặc khi chúng tôi tin rằng việc
          làm đó là cần thiết và phù hợp với quy định pháp luật. Nó chắc chắn
          không bao gồm việc bán, chia sẻ dẫn đến việc làm lộ thông tin cá nhân
          của khách hàng vì mục đích thương mại, vi phạm những cam kết được đặt
          ra trong quy định Chính sách bảo mật thông tin khách hàng của chúng
          tôi.
        </li>
      </ul>

      <h2 id="information-usage">4. Thời gian lưu trữ thông tin: </h2>
      <p>
        Chúng tôi sẽ lưu trữ các thông tin cá nhân do khách hàng cung cấp trên
        các hệ thống nội bộ của chúng tôi trong quá trình cung cấp sản phẩm cho
        khách hàng, cho đến khi hoàn thành mục đích thu thập, hoặc khi khách
        hàng có yêu cầu hủy các thông tin đã cung cấp.
      </p>

      <h2 id="information-sharing">
        5. Cam kết bảo mật thông tin cá nhân khách hàng
      </h2>
      <p>
        Công ty Cổ phần Công nghệ Sức khỏe Tiện lợi cam kết bảo đảm an toàn
        thông tin cho quý khách hàng khi đăng ký thông tin cá nhân với chúng
        tôi. Chúng tôi cam kết không trao đổi mua bán thông tin khách hàng vì
        mục đích thương mại và mọi sự chia sẻ cũng như việc sử dụng thông tin
        của khách hàng sẽ được thực hiện theo chính sách bảo mật đã được công
        khai trên Cvhtechnology.com. Chúng tôi cam kết sẽ khiến quý khách cảm
        thấy tin tưởng và hài lòng về việc bảo mật thông tin cá nhân khi tham
        gia và sử dụng website của công ty.
      </p>

      <h2 id="information-rm">
        6. Những người hoặc tổ chức có thể được tiếp cận với thông tin:
      </h2>
      <p>
        Hoạt động kinh doanh của Cvhtechnology.com không bao gồm việc bán các
        thông tin của khách hàng cho bên thứ ba. Website Cvhtechnology.com chỉ
        cung cấp thông tin khách hàng cho các bên được liệt kê dưới đây nhằm đảm
        bảo quyền lợi của khách hàng theo những cam kết bảo mật của
        Cvhtechnology.com:
      </p>
      <ul>
        <li>
          Các đối tác có ký Hợp đồng để thực hiện một phần dịch vụ cho Công ty
          Cổ phần Công nghệ Sức khỏe Tiện lợi (đối tác kỹ thuật, đối tác cung
          cấp dịch vụ vận chuyển giao nhận...). Các đối tác này sẽ nhận được
          những thông tin theo thỏa thuận hợp đồng (có thể 1 phần hoặc toàn bộ
          thông tin tùy theo điều khoản của Hợp đồng) để tiến hành hỗ trợ người
          dùng sử dụng dịch vụ do Công ty cung cấp.
        </li>
        <li>
          Cơ quan nhà nước khi có yêu cầu: Công ty Cổ phần Công nghệ Sức khỏe
          Tiện lợi sẽ cung cấp thông tin của khách hàng để phục vụ quá trình
          điều tra của các cơ quan đó.
        </li>
      </ul>

      <h2 id="security">
        7. Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân:
      </h2>
      <p>CÔNG TY CỔ PHẦN CÔNG NGHỆ SỨC KHỎE TIỆN LỢI</p>
      <p>
        Mã số thuế: 0317643803 do Sở Kế hoạch và Đầu tư Thành phố Hồ Chí Minh
        cấp ngày 11/01/2023.
      </p>
      <p>Điện thoại: 0913 963 938</p>
      <p>Email: cvhtechnology@gmail.com</p>
      <p>Địa chỉ: 128/5A Trần Khắc Chân, F9, quận Phú Nhuận, TP.HCM</p>

      <h2 id="terms">
        8. Phương tiện và công cụ để người dùng tiếp cận và chỉnh sửa dữ liệu cá
        nhân của mình.
      </h2>
      <p>
        Khách hàng có thể thực hiện quyền chỉnh sửa, cập nhật thông tin cá nhân
        của mình bằng cách liên hệ với chúng tôi qua số điện thoại, email hoặc
        địa chỉ liên lạc được công bố dưới chân website: Cvhtechnology.com.
      </p>

      <h2 id="contacting">
        9. Cơ chế tiếp nhận và giải quyết khiếu nại của người tiêu dùng liên
        quan đến việc thông tin cá nhân bị sử dụng sai mục đích hoặc phạm vi đã
        thông báo:
      </h2>
      <p>
        Khi người tiêu dùng phát hiện thông tin cá nhân của mình trên
        Cvhtechnology.com bị sử dụng sai mục đích hoặc ngoài phạm vi sử dụng
        thông tin, người tiêu dùng có thể gửi khiếu nại đến Công ty Cổ phần Công
        nghệ Sức khỏe Tiện lợi theo các cách thức sau:
      </p>
      <ul>
        <li>Gọi điện thoại đến số Hotline: 0913 963 938;</li>
        <li>Gửi email khiếu nại đến địa chỉ: cvhtechnology@gmail.com;</li>
        <li>
          Gửi văn bản khiếu nại theo đường bưu điện đến hoặc gửi trực tiếp tại
          trụ sở công ty, địa chỉ: 94/8 Đỗ Tấn Phong, Phường 09, Quận Phú Nhuận,
          Thành phố Hồ Chí Minh, Việt Nam.
        </li>
      </ul>
      <br />
      <p>
        Thời hạn để Công ty xử lý phản ánh liên quan đến thông tin cá nhân khách
        hàng là 05 (năm) ngày làm việc, kể từ ngày tiếp nhận được khiếu nại của
        khách hàng.
      </p>
      <p>
        Trong mọi trường hợp, Công ty đề cao biện pháp thương lượng, hòa giải
        với khách hàng để thống nhất và đưa ra biện pháp giải quyết, xử lý khiếu
        nại.
      </p>
      <p>
        Trong trường hợp hai bên không đạt được sự thỏa thuận như mong muốn dẫn
        đến thương lượng, hòa giải không thành, một trong hai bên có quyền đưa
        vụ việc ra tòa án nhân dân có thẩm quyền để giải quyết theo quy định của
        pháp luật.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
