import React from "react";
import { Helmet } from "react-helmet";

function PaymentPolicy() {
  return (
    <div className="privacy-content" id="PaymentPolicy">
      <Helmet>
        <title>Chính sách thanh toán</title>
      </Helmet>
      <h1>Chính sách thanh toán</h1>
      <h2>
        A. CÁC PHƯƠNG THỨC THANH TOÁN KHI ĐĂNG KÝ MUA GÓI DỊCH VỤ TRỰC TUYẾN:
      </h2>
      <b>1. Ví ZaloPay</b>
      <p>
        Sau khi lựa chọn phương thức này và nhấn nút “Tiếp tục”, người dùng sẽ
        được chuyển đến cổng thanh toán ZaloPay
      </p>
      <p>
        Người dùng cần mở ứng dụng ZaloPay trên di động, bấm nút Thanh toán và
        quét QR trên màn hình. Sau khi kiểm tra đầy đủ thông tin, người dùng bấm
        xác nhận thanh toán.
      </p>
      <br />
      <b>2. Ví MoMo</b>
      <p>
        Sau khi lựa chọn phương thức này và nhấn nút “Tiếp tục”, người dùng sẽ
        được chuyển đến cổng thanh toán MoMo.
      </p>
      <p>
        Người dùng cần mở ứng dụng MoMo trên điện thoại, bấm nút Thanh toán và
        quét QR trên màn hình. Sau khi kiểm tra đầy đủ thông tin, người dùng bấm
        xác nhận thanh toán.
      </p>
      <h2>B. CAM KẾT BẢO MẬT THANH TOÁN:</h2>
      <p>
        Hệ thống thanh toán thẻ được cung cấp bởi các đối tác cổng thanh toán
        ZaloPay và MoMo (“Đối Tác Cổng Thanh Toán”) đã được cấp phép hoạt động
        hợp pháp tại Việt Nam. Theo đó, các tiêu chuẩn bảo mật thanh toán thẻ
        tại website Cvhtechnology.com đảm bảo tuân thủ theo các tiêu chuẩn bảo
        mật ngành.
      </p>
      <b>
        <u>
          {" "}
          <i>Quy định bảo mật thanh toán</i>
        </u>
      </b>
      <p>
        Chính sách giao dịch thanh toán bằng thẻ quốc tế và thẻ nội địa đảm bảo
        tuân thủ các tiêu chuẩn bảo mật của Đối Tác Cổng Thanh Toán gồm:
      </p>
      <p>
        1. Thông tin tài chính của Khách hàng sẽ được bảo vệ trong suốt quá
        trình giao dịch bằng giao thức SSL (Secure Sockets Layer):
      </p>
      <ul>
        <li>
          Chứng nhận tiêu chuẩn bảo mật dữ liệu thông tin thanh toán (PCI DSS)
          do Trustwave cung cấp.
        </li>
        <li>
          Mật khẩu sử dụng một lần (OTP) được gửi qua SMS để đảm bảo việc truy
          cập tài khoản được xác thực.
        </li>
        <li>Tiêu chuẩn mã hóa MD5 128 bit.</li>
        <li>
          Các nguyên tắc và quy định bảo mật thông tin trong ngành tài chính
          ngân hàng theo quy định của Ngân hàng nhà nước Việt Nam.
        </li>
      </ul>
      <br />
      <p>
        2. Chính sách bảo mật giao dịch trong thanh toán của Cvhtechnology.com
        áp dụng với Khách hàng:
      </p>
      <p>
        Cvhtechnology.com cung cấp tiện ích lưu giữ token – chỉ lưu chuỗi đã
        được mã hóa bởi Đối Tác Cổng Thanh Toán cung cấp cho Cvhtechnology.com.
        Cvhtechnology.com không trực tiếp lưu trữ thông tin thẻ khách hàng. Việc
        bảo mật thông tin thẻ thanh toán Khách hàng được thực hiện bởi Đối Tác
        Cổng Thanh Toán.
      </p>
      <ul>
        <li>
          Đối với thẻ quốc tế: thông tin thẻ thanh toán của Khách hàng mà có khả
          năng sử dụng để xác lập giao dịch không được lưu trên hệ thống của
          Cvhtechnology.com.{" "}
        </li>
        <li>
          Đối với thẻ nội địa: Cvhtechnology.com chỉ lưu trữ mã đơn hàng, mã
          giao dịch và tên ngân hàng.
        </li>
      </ul>
      <p>
        Cvhtechnology.com cam kết đảm bảo thực hiện nghiêm túc các biện pháp bảo
        mật cần thiết cho mọi hoạt động thanh toán thực hiện trên website
        Cvhtechnology.com
      </p>
    </div>
  );
}

export default PaymentPolicy;
